<template lang="">
    <div>
            <div v-if="skeletonLoading">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-skeleton-loader
                        min-height="600px"
                        type="article, article, article, actions"
                        >
                        </v-skeleton-loader>
                </v-col>
                </v-row>
            </div>

            <div v-if="!skeletonLoading">

                <v-card class="mt-6" outlined>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                    <v-subheader class="text-h6 black--text">
                                        Carga de documentos de la ejecución física
                                    </v-subheader>
                            
                                    <v-divider class="mt-0 pt-0"></v-divider>
                            </v-col>
                        </v-row>

                        <v-form
                            ref="form"
                            v-on:submit.prevent="cargarArchivo()"
                            v-model="validFormArchivo"
                        >
                            <v-row>

                                <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                                    <v-text-field
                                        dense
                                        filled
                                        autocomplete="off"
                                        class="required"
                                        color="blue-grey lighten-2"
                                        v-model="datosDocumento.nombre"
                                        label="Nombre del documento"
                                        :rules="[
                                            required('Nombre del documento'),
                                            minLength('Nombre del documento', 1),
                                            maxLength('Nombre del documento', 100)
                                        ]"
                                        maxlength="100"
                                        ></v-text-field>
                                </v-col>
                            
                                <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                                    <template>
                                        <v-file-input
                                        id="docFile"
                                        dense
                                        filled
                                        placeholder="Seleccionar archivo .pdf"
                                        :label="'Seleccione para ' + datosDocumento.nombre"
                                        append-icon="mdi-file-pdf-outline"
                                        prepend-icon=""
                                        accept="application/pdf,image/jpeg"
                                        @change="onFileChange"
                                        :rules="[fileRequired('documento')]"
                                        :show-size="1000"
                                        ref="docFile"
                                        class="required"
                                        >
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip
                                            v-if="index < 2"
                                            small
                                            label
                                            color="primary"
                                            >
                                            {{ text }}
                                            </v-chip>
                                            <span
                                            v-else-if="index === 2"
                                            class="
                                                text-overline
                                                grey--text
                                                text--darken-3
                                                mx-2
                                            "
                                            >
                                            +{{ files.length - 2 }} archivo(s)
                                            </span>
                                        </template>
                                    </v-file-input>
                                </template>
                                </v-col>

                                <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                                
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-btn
                                        color="light-blue-502"
                                        class="white--text mb-2 float-right"
                                        type="submit"
                                        :elevation="0"
                                        :disabled="!validFormArchivo"
                                        :loading="btnRegistroDocumentoLoading"
                                    >
                                        Agregar documento
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>


                        <v-row>

                            <v-divider></v-divider>


                                <!--inicio:: tabla de documentos cargados -->
                                <v-col cols="12" md="12" sm="12" class="pt-4">
                                    <v-data-table
                                        class="elevation-1"
                                        :headers="headersDocumentos"
                                        :items="documentosCargados"
                                        :loading="tableDocumentosLoading"
                                        hide-default-footer
                                        :footer-props="{
                                            showFirstLastPage: true,
                                            firstIcon: 'mdi-page-first',
                                            lastIcon: 'mdi-page-last',
                                            prevIcon: 'mdi-chevron-left',
                                            nextIcon: 'mdi-chevron-right',
                                            'items-per-page-text': 'Registros por página',
                                            pageText: '{0}-{1} de {2}'
                                        }"
                                        >

                                            <template v-slot:item="{ item }">
                                            <tr>
                                                 <td>{{ item.id }}</td>
                                                <td>{{ item.nombre }}</td>
                                                <td>
                                                    <v-chip
                                                        label
                                                        class="ma-2"
                                                        :color="
                                                            item.estadosId === 1
                                                            ? 'blue-grey lighten-4'
                                                            : item.estadosId === 2
                                                            ? 'deep-orange lighten-4'
                                                            : 'pink lighten-4'
                                                        "
                                                        :text-color="
                                                            item.estadosId === 1
                                                            ? 'blue-grey lighten-1'
                                                            : item.estadosId === 2
                                                            ? 'deep-orange lighten-1'
                                                            : 'pink darken-1'
                                                        "
                                                        small
                                                        >
                                                        {{ item.estado }}
                                                        </v-chip>
                                                </td>                                
                                                <td>
                                                    <v-btn v-if="item.estadosId==1"
                                                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                        small
                                                        depressed
                                                        color="blue-grey lighten-5"
                                                        @click="descargarArchivo(item.path)"
                                                        >
                                                        <v-icon left>mdi-file-download-outline</v-icon> Descargar
                                                    </v-btn>
                                                    <v-btn v-if="item.estadosId==1"
                                                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                        small
                                                        depressed
                                                        :disabled="btnEliminarDisabled"
                                                        color="blue-grey lighten-5"
                                                        @click="eliminarDocumento(item.id)"
                                                        >
                                                        <v-icon left>mdi-delete</v-icon> Eliminar documento
                                                    </v-btn>

                                                </td>
                                            </tr>
                                        </template>

                                        <template v-slot:no-data>
                                            <p class="text-h7">
                                                <v-icon left>mdi-alert</v-icon> No existen documentos cargados
                                            </p>
                                        </template>

                                    </v-data-table>

                                </v-col>
                                <!--fin:: tabla de documentos cargados -->

                            </v-row>

                    </v-card-text>
                </v-card>
            

                <DialogLoader
                    :dialogVisible="dialogLoaderVisible"
                    :text="dialogLoaderText"
                    transition="scroll-y-transition"
                ></DialogLoader>
                
                <!--Inicio:: Snack alert-->
                <SnackAlert ref="snackalert"></SnackAlert>
                <!-- Fin:: Snack alert-->
        </div>
    </div>
</template>
<script>

//import Vue from 'vue';
//import moment from "moment";
import DownloadFile from "@/core/untils/downloadfile.js";
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import { OBTENER_DOCUMENTOS_SEGUIMIENTO_INICIATIVA,
        REGISTRAR_DOCUMENTO_SEGUIMIENTO_INICIATIVA,
        ELIMINAR_DOCUMENTO_SEGUIMIENTO_INICIATIVA

     } from "@/core/services/store/negociaciones/iniciativas/seguimientos/seguimiento.module";


export default {
    name: "SeccionDocumentosSeguimiento",
    props: ['seguimientoId'],
    components: {
        DialogLoader,
        SnackAlert
    },
    data() {
        return {
            skeletonLoading: false,
            validFormArchivo: false,
            tableDocumentosLoading: false,
            btnRegistroDocumentoLoading: false,
            btnEliminarDisabled: false,
            documentosCargados: [],
            datosDocumento: {
                id: 0,
                nombre: "",
                path: "",
                seguimientoId: 0,
                estadosId: 1,
                usuarioCreacion: "admin",

            },
            archivo: [],
            ...validations
        }
    },

    methods: {

        onFileChange(e) {
            if (e) {
                //!?Validar que el tipo de archivo sea pdf
                if (e.type != "application/pdf" && e.type != "image/jpeg") {
                // Api call
                this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `El archivo que desea ingresar no es válido.`
                );
                //console.log("no es un pdf..")
                //this.$refs.file.reset();
                document.querySelector("#docFile").value = "";
                this.$refs.docFile.reset();
                return false;
                }
                //?Validar que el tamaó del archivo no exceda los 10Mb
                if (e.size / 1024 / 1024 > 10) {
                this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `El tamaño del archivo excede el límite permitido (10Mb)`
                );
                // this.$refs.file.reset();
                document.querySelector("#docFile").value = "";
                this.$refs.docFile.reset();
                return false;
                }

                this.archivo = e;
            }
        },

        resetItems(){
            this.skeletonLoading = true;
        },

        async obtenerDocumentosSeguimiento(seguimientoId){
            this.documentosCargados = [];
            
            this.tableDocumentosLoading=true;
            this.$store
                .dispatch(OBTENER_DOCUMENTOS_SEGUIMIENTO_INICIATIVA, {id: seguimientoId})
                .then(res => {
                //console.log(res);
                    if (res.status === 200) {
                        this.documentosCargados = res.data;                   
                    } 
                    this.tableDocumentosLoading = false;
                    this.skeletonLoading = false;
                })
                .catch(error=> {
                    console.log(error)
                    this.skeletonLoading = false;
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`);
                
                });
        },




        //Cargar el documento o imagen (tipo 1 y 2)
        async cargarArchivo(){
           // console.log(tipo)
            let files = this.archivo;
            let path ="\\css\\iniciativas\\documentos\\seguimiento\\documentosadicionales\\";

           
            this.btnRegistroDocumentoLoading=true;
                      

            await 
            this.$store.dispatch(CARGAR_ARCHIVO, {file: files, path: path})
            .then(res => {
                //console.log(res)
                if(res.status===200){
                    this.archivoCargado = true;
                    
                    this.datosDocumento.path = res.pathArchivo;
                        
                    this.registrarPath();
                }
                else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.mensaje);
                    this.archivoCargado = false;
                }
                //this.btnRegistroLoading=false;
            })
            .catch((error) => {
                this.$refs.snackalert('warning', 'Alerta', error);
               
            });
        },

        //Registrar los path en la db
        async registrarPath(){
            
            this.datosDocumento.seguimientoId = this.seguimientoId;
           // this.datosImagen.seguimientoId = this.seguimientoId;

            let datos = this.datosDocumento;
           
            //Tipo 1 documentos, tipo 2 imagenes 
            await this.$store
            .dispatch(REGISTRAR_DOCUMENTO_SEGUIMIENTO_INICIATIVA, { datos: datos})
            .then(res => {
                //console.log(res)
                if(res.status===200){
                   // this.obtnenerItems();
                   // this.dialog=false;
                    this.btnRegistroDocumentoLoading= false;
                    
                    this.obtenerDocumentosSeguimiento(this.datosDocumento.seguimientoId);
                    this.datosDocumento={
                        id: 0,
                        nombre: "",
                        path: "",
                        seguimientoId: 0,
                        estadosId: 1,
                        usuarioCreacion: "admin",
                    };

                    
                    
                   
                    this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                    
                    if(this.archivo && this.archivo.size){
                        //console.log("aui")
                        //document.querySelector('#respaldoFile').value = '';
                        //this.$refs.respaldoFile.reset();
                        this.archivo=[];
                        this.$refs.docFile.reset();
                        //this.$refs['respaldoFile'][0].reset();
                    }

                    this.btnRegistroDocumentoLoading=false;
                    
                } else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                }
                this.btnRegistroDocumentoLoading=false;
            })
            .catch(error => {
                //console.log(error)
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                this.btnRegistroDocumentoLoading=false;
            });


            this.btnRegistroDocumentoLoading = false;
        },


        //Eliminar (dar de baja) documento
        async eliminarDocumento(id){
            this.btnEliminarDisabled=true;
            await this.$store
            .dispatch(ELIMINAR_DOCUMENTO_SEGUIMIENTO_INICIATIVA, id)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerDocumentosSeguimiento(this.seguimientoId);              
                   // this.resetItems();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },


        //!Descargar el archivo de respaldo
        descargarArchivo(path){
            /* let newPath = path.replace(/\\/g, '/');
            //console.log(newPath);
            window.open("http://localhost:5236"+ newPath, "_blank"); */
             DownloadFile.download(path);
        },
    },

    created() {
        this.obtenerDocumentosSeguimiento(this.seguimientoId);
    },

    computed: {
        headersDocumentos (){
            return [
                {
                    text: "Id",
                    align: "start",
                    sortable: true,
                    value: "id"
                },
                {
                    text: "Nombre del documento",
                    align: "start",
                    sortable: true,
                    value: "nombre"
                },
                {
                    text: "Estado",
                    align: "start",
                    sortable: true,
                    value: "estado"
                },
                {
                    text: "Acciones",
                    align: "start",
                    sortable: false,
                    value: ""
                }
            ]
        }
    }
}
</script>
<style lang="">
    
</style>