<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-if="!skeletonLoading">

        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-subheader class="text-h6 black--text">
                                Ejecución física 
                            </v-subheader>
                    
                            <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>


                <v-form 
                        ref="formProyeccionDesembolsos"
                        v-on:submit.prevent="registrarItem"
                        v-model="validForm"
                        >

                    <v-row class="mt-4">
                        <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosEjecucion.porcentajeAvanceEFIS"
                                label="Porcentaje de avance"
                                suffix="%"
                                :rules="[
                                    required('porcentaje de avance'),
                                    minLength('porcentaje de avance', 1),
                                    maxLength('porcentaje de avance', 3),
                                    maxNumber('porcentaje de avance', 100)
                                ]"
                                maxlength="3"
                                v-mask="'###'"
                                ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="12" sm="6" class="pt-0 pb-0">
                            <v-textarea
                                dense
                                filled
                                rows="3"
                                autocomplete="off"
                                class=""
                                color="blue-grey lighten-2"
                                v-model="datosEjecucion.informacionAdicional"
                                label="Información adicional"
                                maxlength="1000"
                                ></v-textarea>
                        </v-col>
                    </v-row>


                    <v-row>

                            <v-col cols="12" md="12" sm="12" class="pt-0">
                                    
                                <v-btn
                                    color="light-blue darken-2"
                                    class="white--text mb-2 float-right"
                                    type="submit"
                                    :elevation="0"
                                    :disabled="!validForm"
                                    :loading="btnRegistroLoading"
                                >
                                    {{btnRegistroAvanceText}}
                                </v-btn>
                            </v-col>


                        </v-row>
                </v-form>
            </v-card-text>
        </v-card>



        <v-card class="mt-6" outlined v-if="cargarDocumento == true">
            <v-card-text>
                <v-form
                    ref="form"
                    v-on:submit.prevent="cargarArchivo(1)"
                    v-model="validFormArchivo"
                >
                    <v-row>

                        <v-col cols="12" md="12" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                            <v-subheader class="text-h6 black--text">
                            Carga de documentos de la ejecución física
                            </v-subheader>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosDocumento.nombre"
                                label="Nombre del documento"
                                :rules="[
                                    required('Nombre del documento'),
                                    minLength('Nombre del documento', 1),
                                    maxLength('Nombre del documento', 100)
                                ]"
                                maxlength="100"
                                ></v-text-field>
                        </v-col>
                    
                        <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                            <template>
                                <v-file-input
                                id="docFile"
                                dense
                                filled
                                placeholder="Seleccionar archivo .pdf"
                                :label="'Seleccione para ' + datosDocumento.nombre"
                                append-icon="mdi-file-pdf-outline"
                                prepend-icon=""
                                accept="application/pdf,image/jpeg"
                                @change="onFileChange"
                                :rules="[fileRequired('documento')]"
                                :show-size="1000"
                                ref="docFile"
                                class="required"
                                >
                                <template v-slot:selection="{ index, text }">
                                    <v-chip
                                    v-if="index < 2"
                                    small
                                    label
                                    color="primary"
                                    >
                                    {{ text }}
                                    </v-chip>
                                    <span
                                    v-else-if="index === 2"
                                    class="
                                        text-overline
                                        grey--text
                                        text--darken-2
                                        mx-2
                                    "
                                    >
                                    +{{ files.length - 2 }} archivo(s)
                                    </span>
                                </template>
                            </v-file-input>
                        </template>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                        
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                color="light-blue darken-2"
                                class="white--text mb-2 float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="!validFormArchivo"
                                :loading="btnRegistroArchivoLoading"
                            >
                                Agregar documento
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>


                <v-row>

                    <v-divider></v-divider>

                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-1"
                            :headers="headersDocumentos"
                            :items="documentos"
                            :loading="tableDocumentosLoading"
                            hide-default-footer
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.nombre }}</td>
                                    <td>
                                        <v-chip
                                            label
                                            class="ma-2"
                                            :color="
                                                item.estadosId === 1
                                                ? 'blue-grey lighten-4'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-4'
                                                : 'pink lighten-4'
                                            "
                                            :text-color="
                                                item.estadosId === 1
                                                ? 'blue-grey lighten-1'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-1'
                                                : 'pink darken-1'
                                            "
                                            small
                                            >
                                            {{ item.estado }}
                                            </v-chip>
                                    </td>                                
                                    <td>
                                        <v-btn v-if="item.estadosId==1"
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            color="blue-grey lighten-5"
                                            @click="descargarArchivo(item.path)"
                                            >
                                            <v-icon left>mdi-file-download-outline</v-icon> Descargar
                                        </v-btn>
                                        <v-btn v-if="item.estadosId==4"
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            color="blue-grey lighten-5"
                                            @click="eliminarArchivo(item.idTipo)"
                                            >
                                            <v-icon left>mdi-delete</v-icon> Eliminar
                                        </v-btn>

                                    </td>
                                </tr>
                            </template>

                            <template v-slot:no-data>
                                <p class="text-h7">
                                    <v-icon left>mdi-alert</v-icon> No existen documentos cargados
                                </p>
                            </template>

                        </v-data-table>

                    </v-col>
                    <!--fin:: tabla de documentos cargados -->
                </v-row>
                
            </v-card-text>
        </v-card>



        <!--inicio:: carga de imagenes ejecucion fisica-->
        <v-card class="mt-6" outlined v-if="cargarDocumento == true">
            <v-card-text>
                <v-form
                    ref="form"
                    v-on:submit.prevent="cargarArchivo(2)"
                    v-model="validFormImagen"
                >
                    <v-row>

                        <v-col cols="12" md="12" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                            <v-subheader class="text-h6 black--text">
                                Carga de imágenes de la ejecución física
                            </v-subheader>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosImagen.nombre"
                                label="Nombre de la imagen"
                                :rules="[
                                    required('Nombre de la imagen'),
                                    minLength('Nombre de la imagen', 1),
                                    maxLength('Nombre de la imagen', 100)
                                ]"
                                maxlength="100"
                                ></v-text-field>
                        </v-col>
                    
                        <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                            <template>
                                <v-file-input
                                id="imageFile"
                                dense
                                filled
                                placeholder="Seleccionar archivo jpg, png"
                                :label="'Seleccione para ' + datosImagen.nombre"
                                append-icon="mdi-file-pdf-outline"
                                prepend-icon=""
                                accept="application/image/jpeg"
                                @change="onFileChangeImage"
                                :rules="[fileRequired('imagen')]"
                                :show-size="1000"
                                ref="imageFile"
                                class="required"
                                >
                                <template v-slot:selection="{ index, text }">
                                    <v-chip
                                    v-if="index < 2"
                                    small
                                    label
                                    color="primary"
                                    >
                                    {{ text }}
                                    </v-chip>
                                    <span
                                    v-else-if="index === 2"
                                    class="
                                        text-overline
                                        grey--text
                                        text--darken-2
                                        mx-2
                                    "
                                    >
                                    +{{ files.length - 2 }} archivo(s)
                                    </span>
                                </template>
                            </v-file-input>
                        </template>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                        
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                color="light-blue darken-2"
                                class="white--text mb-2 float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="!validFormImagen"
                                :loading="btnRegistroImagenLoading"
                            >
                                Agregar imagen
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>


                <v-row>

                    <v-divider></v-divider>

                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-1"
                            :headers="headersDocumentos"
                            :items="imagenes"
                            :loading="tableImagenesLoading"
                            hide-default-footer
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.nombre }}</td>
                                    <td>
                                        <v-chip
                                            label
                                            class="ma-2"
                                            :color="
                                                item.estadosId === 1
                                                ? 'blue-grey lighten-4'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-4'
                                                : 'pink lighten-4'
                                            "
                                            :text-color="
                                                item.estadosId === 1
                                                ? 'blue-grey lighten-1'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-1'
                                                : 'pink darken-1'
                                            "
                                            small
                                            >
                                            {{ item.estado }}
                                            </v-chip>
                                    </td>                                
                                    <td>
                                        <v-btn v-if="item.estadosId==1"
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            color="blue-grey lighten-5"
                                            @click="descargarArchivo(item.path)"
                                            >
                                            <v-icon left>mdi-file-download-outline</v-icon> Descargar
                                        </v-btn>
                                        <v-btn v-if="item.estadosId==4"
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            color="blue-grey lighten-5"
                                            @click="eliminarArchivo(item.idTipo)"
                                            >
                                            <v-icon left>mdi-delete</v-icon> Eliminar
                                        </v-btn>

                                    </td>
                                </tr>
                            </template>

                            <template v-slot:no-data>
                                <p class="text-h7">
                                    <v-icon left>mdi-alert</v-icon> No existen fotografías cargadas
                                </p>
                            </template>

                        </v-data-table>

                    </v-col>
                    <!--fin:: tabla de documentos cargados -->
                </v-row>
                
            </v-card-text>

           
        </v-card>

        <!--fin:: carga de imagenes ejecucion fisica-->






        
        <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
        transition="scroll-y-transition"
        ></DialogLoader>
        
        <!--Inicio:: Snack alert-->
        <SnackAlert ref="snackalert"></SnackAlert>
        <!-- Fin:: Snack alert-->
        
    </div>
    
</div>

</template>

<script>
//import Vue from 'vue';
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import { OBTENER_SEGUIMIENTO_INICIATIVA, 
         ACTUALIZAR_SEGUIMIENTO_INICIATIVA, 
         REGISTRAR_DOCUMENTO_EJECUCION_FISICA, 
         OBTENER_DOCUMENTOS_EJECUCION_FISICA,
         OBTENER_FOTOGRAFIAS_EJECUCION_FISICA } from "@/core/services/store/negociaciones/iniciativas/seguimientos/seguimiento.module";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";


export default {
    name: "SeccionEjecucionFisicaSeguimiento",
    components: {
        DialogLoader,
        SnackAlert
    },
    props: ['seguimientoId'],
    data() {
        return {
                
                skeletonLoading: false,
                validForm: false,
                validFormArchivo:false,
                validFormImagen: false,
                btnRegistroImagenLoading: false,
                btnRegistroLoading: false,
                btnRegistroArchivoLoading:false,
                tableLoading: false,
                tableDocumentosLoading: false,
                documentos: [],
                imagenes: [],
                tableImagenesLoading: false,
                btnRegistroAvanceText: "Registrar avance",
                dialogLoaderText: "",
                dialogLoaderVisible:false,
                items: [],
                datosEjecucion: {
                    id: 0,
                    porcentajeAvanceEFIS: 0,
                    informacionAdicional: "",
                    seguimientoId: this.seguimientoId,
                    estadosId: 1,
                    usuarioCreacion: "string",
                },
                cargarDocumento:false,
                archivo:[],
                datosDocumento:{
                    nombre: "",
                    path: "",
                    ejecucionFisicaId: 0,
                    estadosId: 1,
                    usuarioCreacion: "admin"
                },
                archivoImagen: [],
                datosImagen:{
                    nombre: "",
                    path: "",
                    ejecucionFisicaId: 0,
                    estadosId: 1,
                    usuarioCreacion: "admin"
                },
                ...validations

            }
        
    },

    methods: {

        onFileChange(e) {
            if (e) {
                //!?Validar que el tipo de archivo sea pdf
                if (e.type != "application/pdf" && e.type != "image/jpeg") {
                // Api call
                this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `El archivo que desea ingresar no es válido.`
                );
                //console.log("no es un pdf..")
                //this.$refs.file.reset();
                document.querySelector("#docFile").value = "";
                this.$refs.docFile.reset();
                return false;
                }
                //?Validar que el tamaó del archivo no exceda los 10Mb
                if (e.size / 1024 / 1024 > 10) {
                this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `El tamaño del archivo excede el límite permitido (10Mb)`
                );
                // this.$refs.file.reset();
                document.querySelector("#docFile").value = "";
                this.$refs.docFile.reset();
                return false;
                }

                this.archivo = e;
            }
        },


        onFileChangeImage(e) {
            if (e) {
                //!?Validar que el tipo de archivo sea pdf
                if (e.type != "image/jpeg") {
                // Api call
                this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `El archivo que desea ingresar no es válido.`
                );
                //console.log("no es un pdf..")
                //this.$refs.file.reset();
                document.querySelector("#imageFile").value = "";
                this.$refs.imageFile.reset();
                return false;
                }
                //?Validar que el tamaó del archivo no exceda los 10Mb
                if (e.size / 1024 / 1024 > 10) {
                this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `El tamaño del archivo excede el límite permitido (10Mb)`
                );
                // this.$refs.file.reset();
                document.querySelector("#imageFile").value = "";
                this.$refs.imageFile.reset();
                return false;
                }

                this.archivoImagen = e;
            }
        },


        resetItems(){
            return 0;
        },



        async obtenerDatosSeguimiento(seguimientoId){
            this.datosEjecucion = [];
            this.skeletonLoading = true;
            this.$store
                .dispatch(OBTENER_SEGUIMIENTO_INICIATIVA, seguimientoId)
                .then(res => {
                //console.log(res);
                if (res.status === 200) {
                    this.datosEjecucion = res.data;
                   // this.datosEjecucion.porcentajeAvance;

                    this.btnRegistroAvanceText = "Actualizar información";
                    this.obtenerDocumentosCargados(this.seguimientoId);
                    this.obtenerImagenesCargadas(this.seguimientoId);
                    this.cargarDocumento=true;
                } else {
                    this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
                    );
                }
               
                })
                .catch(() => {
                this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
                );
                
                });
        },
        
        /*async obtenerItems(){
            this.items = [];
            this.tableLoading = true;
         
            this.$store
                .dispatch(OBTENER_EJECUCIONES_FISICAS_SEGUIMIENTO, { id: this.seguimientoId})
                .then(res => {
                    if(res.status === 200) {
                        this.items = res.data;
                    }

                     this.tableLoading = false;
                     this.skeletonLoading=false;
                })
                .catch(() => {
                     this.skeletonLoading=false;
                     this.tableContactosLoading=false;
                });
        },*/

        async registrarItem(){
            this.btnRegistroLoading=true;
            this.datosEjecucion.seguimientoId = this.seguimientoId;
           
            await this.$store
            .dispatch(ACTUALIZAR_SEGUIMIENTO_INICIATIVA, {datosItem: this.datosEjecucion, id: this.datosEjecucion.seguimientoId, endpoint: "EjecucionFisica"})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.cargarDocumento=true;
                   // this.obtenerItems(this.seguimientoId);       
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);

                    this.$emit('obtener-items');
                          
                    //this.resetItems(2);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroLoading=false;
            })
            .catch(error => {
                console.log(error)
                 this.btnRegistroLoading=false;
                 this.$refs.snackalert.SnackbarShow('warning', 'Alerta', `Ha ocurrido un error durante el registro. ${error}`);
                 //this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`);
            });
        },


        configurarCargaDocumento(id){
            this.cargarDocumento=true;
            this.datosDocumento.ejecucionFisicaId = id;
            this.obtenerDocumentosCargados(id);

        },


        //Cargar el documento o imagen (tipo 1 y 2)
        async cargarArchivo(tipo){
           // console.log(tipo)
            let files = this.archivo;
            let path ="\\css\\iniciativas\\documentos\\ejecucionfisica\\";

            if(tipo === 1 ){
                 this.btnRegistroArchivoLoading=true;
            }
            if(tipo===2){
                path ="\\css\\iniciativas\\imagenes\\ejecucionfisica\\";
                files = this.archivoImagen;
                this.btnRegistroImagenLoading = true;
            }


           
            this.datosArchivo={};
            

            await 
            this.$store.dispatch(CARGAR_ARCHIVO, {file: files, path: path})
            .then(res => {
                //console.log(res)
                if(res.status===200){
                    this.archivoCargado = true;
                    
                        tipo===1 ? this.datosDocumento.path = res.pathArchivo : this.datosImagen.path = res.pathArchivo;
                        
                    this.registrarPath(tipo);
                }
                else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.mensaje);
                    this.archivoCargado = false;
                }
                //this.btnRegistroLoading=false;
            })
            .catch((error) => {
                this.$refs.snackalert('warning', 'Alerta', error);
               
            });
        },

        //Registrar los path en la db
        async registrarPath(tipo){
            
            this.datosDocumento.seguimientoId = this.seguimientoId;
            this.datosImagen.seguimientoId = this.seguimientoId;

            let datos = this.datosDocumento;
            if(tipo===2){ 
                datos = this.datosImagen;
            }
            //Tipo 1 documentos, tipo 2 imagenes 
            await this.$store
            .dispatch(REGISTRAR_DOCUMENTO_EJECUCION_FISICA, { datos: datos, tipo: tipo})
            .then(res => {
                //console.log(res)
                if(res.status===200){
                   // this.obtnenerItems();
                   // this.dialog=false;
                    this.btnRegistroArchivoLoading= false;
                     this.btnRegistroImagenLoading = false;
                    if(tipo===1){
                        this.obtenerDocumentosCargados(this.datosDocumento.seguimientoId);
                        this.datosDocumento={
                            nombre: "",
                            path: "",
                            ejecucionFisicaId: 0,
                            estadosId: 1,
                            usuarioCreacion: "admin"
                        };
                    } else {
                        this.obtenerImagenesCargadas(this.datosDocumento.seguimientoId);
                                                
                        this.datosImagen={
                            nombre: "",
                            path: "",
                            ejecucionFisicaId: 0,
                            estadosId: 1,
                            usuarioCreacion: "admin"
                        };
                    }
                    this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                    
                    if(this.archivo && this.archivo.size){
                        //console.log("aui")
                        //document.querySelector('#respaldoFile').value = '';
                        //this.$refs.respaldoFile.reset();
                        this.archivo=[];
                        this.$refs.docFile.reset();
                        //this.$refs['respaldoFile'][0].reset();
                    }

                    if(this.archivoImagen && this.archivoImagen.size){
                        //console.log("aui")
                        //document.querySelector('#respaldoFile').value = '';
                        //this.$refs.respaldoFile.reset();
                        this.archivoImagen=[];
                        this.$refs.imageFile.reset();
                        //this.$refs['respaldoFile'][0].reset();
                    }

                    this.btnRegistroArchivoLoading=false;
                    
                } else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                }
                this.btnRegistroArchivoLoading=false;
            })
            .catch(error => {
                //console.log(error)
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                this.btnRegistroArchivoLoading=false;
            });


            this.btnRegistroArchivoLoading = false;
        },


        async obtenerDocumentosCargados(seguimientoId){
            
            this.documentos = [];
            this.tableDocumentosLoading = true;
         
            await this.$store
                .dispatch(OBTENER_DOCUMENTOS_EJECUCION_FISICA, { id: seguimientoId})
                .then(res => {
                    if(res.status === 200) {
                        this.documentos = res.data;
                    }

                     this.tableDocumentosLoading = false;
                     this.skeletonLoading=false;
                })
                .catch(() => {
                     this.tableDocumentosLoading=false;
                     this.tableContactosLoading=false;
                });
        },



        async obtenerImagenesCargadas(seguimientoId){
            this.imagenes = [];
            this.tableImagenesLoading = true;
         
            await this.$store
                .dispatch(OBTENER_FOTOGRAFIAS_EJECUCION_FISICA, { id: seguimientoId})
                .then(res => {
                    if(res.status === 200) {
                        this.imagenes = res.data;
                    }

                     this.tableImagenesLoading = false;
                     this.skeletonLoading=false;
                })
                .catch(error => {
                    console.log(error)
                     this.tableImagenesLoading=false;
                     this.tableContactosLoading=false;
                });
        },


        //!Descargar el archivo de respaldo
        descargarArchivo(path){
            let newPath = path.replace(/\\/g, '/');
            //console.log(http://205.209.122.7);
            //http://localhost:5236
            window.open("http://205.209.122.7"+ newPath, "_blank");
        },

    },

    created(){
        //this.obtenerItems(this.seguimientoId);
        this.obtenerDatosSeguimiento(this.seguimientoId);
        //this.obtenerImagenesCargadas(this.seguimientoId);
        //this.obtenerDocumentosCargados(this.seguimientoId);
    },
    computed: {

        headers() {
            return [
                {
                    text: "Id",
                    align: "start",
                    sortable: true,
                    value: "id"
                },
                {
                    text: "Porcentaje",
                    align: "start",
                    sortable: true,
                    value: "porcentajeAvance"
                },
                {
                    text: "Información adicional",
                    align: "start",
                    sortable: true,
                    value: "informacionAdicional"
                },
                {
                    text: "Estado",
                    align: "start",
                    sortable: true,
                    value: "estado"
                },
                {
                    text: "Acciones",
                    align: "start",
                    sortable: false,
                    value: ""
                }
                
            ]
        },

        headersDocumentos() {
            {
            return [
                {
                    text: "Nombre del documento",
                    align: "start",
                    sortable: false,
                    value: "tipoDocumento"
                },
                {
                    text: "Estado",
                    align: "start",
                    sortable: false,
                    value: "estado"
                },
                {
                    text: "Acciones",
                    align: "start",
                    sortable: true,
                    value: "nombreMoneda"
                },
            ]
            }
        }
    }
}

</script>