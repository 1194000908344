var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Ejecución física ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-form',{ref:"formProyeccionDesembolsos",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Porcentaje de avance","suffix":"%","rules":[
                                    _vm.required('porcentaje de avance'),
                                    _vm.minLength('porcentaje de avance', 1),
                                    _vm.maxLength('porcentaje de avance', 3),
                                    _vm.maxNumber('porcentaje de avance', 100)
                                ],"maxlength":"3"},model:{value:(_vm.datosEjecucion.porcentajeAvanceEFIS),callback:function ($$v) {_vm.$set(_vm.datosEjecucion, "porcentajeAvanceEFIS", $$v)},expression:"datosEjecucion.porcentajeAvanceEFIS"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"6"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","rows":"3","autocomplete":"off","color":"blue-grey lighten-2","label":"Información adicional","maxlength":"1000"},model:{value:(_vm.datosEjecucion.informacionAdicional),callback:function ($$v) {_vm.$set(_vm.datosEjecucion, "informacionAdicional", $$v)},expression:"datosEjecucion.informacionAdicional"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue darken-2","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" "+_vm._s(_vm.btnRegistroAvanceText)+" ")])],1)],1)],1)],1)],1),(_vm.cargarDocumento == true)?_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.cargarArchivo(1)}},model:{value:(_vm.validFormArchivo),callback:function ($$v) {_vm.validFormArchivo=$$v},expression:"validFormArchivo"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Carga de documentos de la ejecución física ")])],1),_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Nombre del documento","rules":[
                                    _vm.required('Nombre del documento'),
                                    _vm.minLength('Nombre del documento', 1),
                                    _vm.maxLength('Nombre del documento', 100)
                                ],"maxlength":"100"},model:{value:(_vm.datosDocumento.nombre),callback:function ($$v) {_vm.$set(_vm.datosDocumento, "nombre", $$v)},expression:"datosDocumento.nombre"}})],1),_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[[_c('v-file-input',{ref:"docFile",staticClass:"required",attrs:{"id":"docFile","dense":"","filled":"","placeholder":"Seleccionar archivo .pdf","label":'Seleccione para ' + _vm.datosDocumento.nombre,"append-icon":"mdi-file-pdf-outline","prepend-icon":"","accept":"application/pdf,image/jpeg","rules":[_vm.fileRequired('documento')],"show-size":1000},on:{"change":_vm.onFileChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var index = ref.index;
                                var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"\r\n                                        text-overline\r\n                                        grey--text\r\n                                        text--darken-2\r\n                                        mx-2\r\n                                    "},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" archivo(s) ")]):_vm._e()]}}],null,false,570443550)})]],2),_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue darken-2","type":"submit","elevation":0,"disabled":!_vm.validFormArchivo,"loading":_vm.btnRegistroArchivoLoading}},[_vm._v(" Agregar documento ")])],1)],1)],1),_c('v-row',[_c('v-divider'),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDocumentos,"items":_vm.documentos,"loading":_vm.tableDocumentosLoading,"hide-default-footer":"","footer-props":{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.nombre))]),_c('td',[_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":item.estadosId === 1
                                                ? 'blue-grey lighten-4'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-4'
                                                : 'pink lighten-4',"text-color":item.estadosId === 1
                                                ? 'blue-grey lighten-1'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-1'
                                                : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[(item.estadosId==1)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.descargarArchivo(item.path)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" Descargar ")],1):_vm._e(),(item.estadosId==4)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarArchivo(item.idTipo)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ")],1):_vm._e()],1)])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"text-h7"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" No existen documentos cargados ")],1)]},proxy:true}],null,false,1430234057)})],1)],1)],1)],1):_vm._e(),(_vm.cargarDocumento == true)?_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.cargarArchivo(2)}},model:{value:(_vm.validFormImagen),callback:function ($$v) {_vm.validFormImagen=$$v},expression:"validFormImagen"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Carga de imágenes de la ejecución física ")])],1),_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Nombre de la imagen","rules":[
                                    _vm.required('Nombre de la imagen'),
                                    _vm.minLength('Nombre de la imagen', 1),
                                    _vm.maxLength('Nombre de la imagen', 100)
                                ],"maxlength":"100"},model:{value:(_vm.datosImagen.nombre),callback:function ($$v) {_vm.$set(_vm.datosImagen, "nombre", $$v)},expression:"datosImagen.nombre"}})],1),_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[[_c('v-file-input',{ref:"imageFile",staticClass:"required",attrs:{"id":"imageFile","dense":"","filled":"","placeholder":"Seleccionar archivo jpg, png","label":'Seleccione para ' + _vm.datosImagen.nombre,"append-icon":"mdi-file-pdf-outline","prepend-icon":"","accept":"application/image/jpeg","rules":[_vm.fileRequired('imagen')],"show-size":1000},on:{"change":_vm.onFileChangeImage},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var index = ref.index;
                                var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"\r\n                                        text-overline\r\n                                        grey--text\r\n                                        text--darken-2\r\n                                        mx-2\r\n                                    "},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" archivo(s) ")]):_vm._e()]}}],null,false,570443550)})]],2),_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue darken-2","type":"submit","elevation":0,"disabled":!_vm.validFormImagen,"loading":_vm.btnRegistroImagenLoading}},[_vm._v(" Agregar imagen ")])],1)],1)],1),_c('v-row',[_c('v-divider'),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDocumentos,"items":_vm.imagenes,"loading":_vm.tableImagenesLoading,"hide-default-footer":"","footer-props":{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.nombre))]),_c('td',[_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":item.estadosId === 1
                                                ? 'blue-grey lighten-4'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-4'
                                                : 'pink lighten-4',"text-color":item.estadosId === 1
                                                ? 'blue-grey lighten-1'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-1'
                                                : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[(item.estadosId==1)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.descargarArchivo(item.path)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" Descargar ")],1):_vm._e(),(item.estadosId==4)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarArchivo(item.idTipo)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ")],1):_vm._e()],1)])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"text-h7"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" No existen fotografías cargadas ")],1)]},proxy:true}],null,false,2458557387)})],1)],1)],1)],1):_vm._e(),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText,"transition":"scroll-y-transition"}}),_c('SnackAlert',{ref:"snackalert"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }