<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                v-bind="attrs"
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

<div v-if="!skeletonLoading">

    <v-row>
        <v-col cols="12" md="9" sm="8" xs="12" class="pt-0 pb-3">
            <v-subheader class="text-h6 black--text">
                Seguimiento de actividades
            </v-subheader>
        </v-col>
        <v-col cols="12" md="3" sm="4" xs="12">
          <v-btn 
            color="light-blue-502"
            dark
            class="white--text mb-2 float-right"
            @click="mostrarDialogActividad()"
          >
            <v-icon left>mdi-plus</v-icon> Agregar Actividad no Prevista
          </v-btn>
        </v-col>
    </v-row>

    <v-card  class="mt-2 mb-4 pb-2" v-if="mostrarRegistroActividad">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="10" sm="8" xs="12" class="pt-0 pb-3">
              <v-subheader class="text-subtitle-1 black--text">
                  Registrar nueva actividad no prevista
              </v-subheader>
          </v-col>
        </v-row>
        <v-form
              ref="form"
              v-on:submit.prevent="agregarActividad"
              v-model="validFormActividad"
            >
            <v-row>
              <!--inicio:: campo -->
              <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosActividad.tipoActividadId"
                  :items="tiposActividad"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Tipo de actividad"
                  item-text="tipo"
                  item-value="id"
                  :rules="[selectRequired('Modalidad')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->
        
              <!--inicio:: campo -->
            <!--  <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.sectoresId"
                  :items="sectores"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Sector"
                  item-text="sector"
                  item-value="id"
                  :rules="[selectRequired('Modalidad')]"
                  menu-props="auto"
                  
                >
                </v-select>
              </v-col> -->
              <!--fin:: campo-->
            </v-row>
        
            <v-row>
              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="fechaPrevista"
                  label="Fecha prevista de la actividad"
                  :rules="[
                    required('Fecha prevista'),
                    minLength('Fecha prevista', 8),
                    dateFormat('Fecha prevista')
                  ]"
                  maxlength="50"
                  v-mask="'##/##/####'"
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->
        
              <!--inicio:: campo -->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-autocomplete
                  dense
                  filled
                  v-model="datosActividad.paisId"
                  :items="paises"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Lugar de la actividad (País)"
                  item-text="nombre"
                  item-value="id"
                  :rules="[selectRequired('país')]"
                  menu-props="auto"
                  
                >
                </v-autocomplete>
              </v-col>
              <!--fin:: campo-->
            </v-row>
        
            <v-row>
              <!--inicio:: campo -->
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-textarea
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosActividad.descripcionActividad"
                  label="Descripción de la actividad"
                  rows="3"
                  :rules="[
                    required('desripción de la actividad'),
                    minLength('desripción de la actividad', 5),
                    maxLength('desripción de la actividad', 200)
                  ]"
                  maxlength="200"
                ></v-textarea>
              </v-col>
              <!--fin:: campo-->
        
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-spacer></v-spacer>
                
                <v-btn
                  class="ma-0 white--text float-right"
                  medium
                  color="light-blue darken-2"
                  type="submit"
                  :loading="btnRegistroActividadLoading"
                  :disabled="!validFormActividad"
                >
                  <v-icon left>mdi-plus</v-icon> Agregar actividad
                </v-btn>

                <v-btn
                  color="grey lighten-5"
                  elevation="0"
                  class="mb-2 float-right grey lighten-5 mr-2"
                  medium
                  @click="mostrarRegistroActividad = false; resetFormActividad();"
                  :disabled="btnRegistroActividadLoading"
                >
                 Cancelar
                </v-btn>

              </v-col>
            </v-row>
          </v-form>

      </v-card-text>
    </v-card>


    <v-row>
      <v-col cols="12" md="12" sm="12">
        
        <template>
          <v-data-table
            :headers="headersActividades"
            :items="actividades"
            :items-per-page="5"
            class="elevation-1"
            :header-props="{ 
              sortByText: 'Ordenar por'
            }"
            no-data-text="No se encontraron registros"
            loading-text="Cargando..."
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              'items-per-page-text': 'Registros por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.descripcionActividad }}</td>
                <td>{{ item.tipo }}</td>
                <td>{{ item.sector }}</td>
                <td>{{ item.fechaPrevista | formatDate }}</td>
                <td>{{ item.pais }}</td>
                <td>
                  <v-chip
                    class="ma-2 font-weight-medium"
                    label
                    :color="
                      item.estadoId === 1
                        ? 'blue-grey lighten-4'
                        : item.estadoId === 2
                        ? 'blue lighten-4'
                        : item.estadoId === 6
                        ? 'cyan lighten-4'
                        : item.estadoId === 7
                        ? 'deep-orange lighten-4'
                        : 'pink lighten-4'
                    "
                    :text-color="
                      item.estadoId === 1
                        ? 'blue-grey lighten-1'
                        : item.estadoId === 2
                        ? 'blue lighten-1'
                        : item.estadoId === 6
                        ? 'cyan darken-1'
                        : item.estadoId === 7
                        ? 'deep-orange lighten-1'
                        : 'pink darken-1'
                    "
                    small
                  >
                    {{ item.estado }}
                  </v-chip>
                </td>
                <td>
                <v-btn v-if="item.estadoId===1"
                    :disabled="btnEliminarActividadDisabled"
                    class=" ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize"
                    small
                    depressed
                    color="blue-grey lighten-5"
                    @click="mostrarFormularioResultados(item.id, item.descripcionActividad)"
                  >
                    <v-icon left>mdi-plus</v-icon> Registrar Resultados
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-col>
    </v-row>
 
      <v-card outlined v-if="formDetalleActividad" class="mt-4">
        <v-card-text>

            <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                    <v-subheader class="text-h6 black--text">
                        Resultados de la actividad | {{actividadSeleccionada}}
                    </v-subheader>
                </v-col>

            </v-row>

            
            <div v-if="skeletonResultadoLoading">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-skeleton-loader
                        v-bind="attrs"
                        min-height="500px"
                        type="list-item-three-line, list-item-three-line, list-item-three-line, actions"
                        >
                        </v-skeleton-loader>
                  </v-col>
                </v-row>
            </div>

            
                
            <v-form ref="form" v-on:submit.prevent="registrarResultado" v-model="validFormResultados" v-if="!skeletonResultadoLoading">
              <v-row class="mt-4">
                  <!--inicio:: campo-->
                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                      <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="inicioRealizacion"
                      label="Fecha en la que inicia la realización"
                      :rules="[
                          required('Fecha en la que inicia la realización'),
                          minLength('Fecha en la que inicia la realización', 8),
                          dateFormat('Fecha en la que inicia la realización')
                      ]"
                      maxlength="50"
                      v-mask="'##/##/####'"
                      ></v-text-field>
                  </v-col>
                  <!--fin:: campo-->

                  <!--inicio:: campo-->
                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                      <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="finalizaRealizacion"
                      label="Fecha en la que finaliza la realización"
                      :rules="[
                          required('Fecha en la que finaliza la realización'),
                          minLength('Fecha en la que finaliza la realización', 8),
                          dateFormat('Fecha en la que finaliza la realización')
                      ]"
                      maxlength="50"
                      v-mask="'##/##/####'"
                      ></v-text-field>
                  </v-col>
                  <!--fin:: campo-->
              </v-row>
                  
                  <v-row>
                      <!--inicio:: campo -->
                      <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                          <v-autocomplete
                          dense
                          filled
                          v-model="datosResultado.paisId"
                          :items="paises"
                          class="required"
                          color="blue-grey lighten-2"
                          label="Lugar de la actividad (País)"
                          item-text="nombre"
                          item-value="id"
                          :rules="[selectRequired('país')]"
                          menu-props="auto"
                          
                          >
                          </v-autocomplete>
                      </v-col>
                      <!--fin:: campo-->


                      <!--inicio:: campo -->
                      <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                          <v-autocomplete
                              dense
                              filled
                              v-model="formatoSeleccionado"
                              :items="formatos"
                              class="required"
                              color="blue-grey lighten-2"
                              label="Formato"
                              item-text="text"
                              return-object
                              item-value="id"
                              :rules="[selectRequired('formato')]"
                              menu-props="auto"
                              
                              >
                          </v-autocomplete>
                      </v-col>
                      <!--fin:: campo-->

                  </v-row>

                  <v-row v-if="datosResultado.paisId===94">

                      <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="datosResultado.paisId===94">
                          <v-select
                              v-model="datosResultado.departamentosId"
                              :items="departamentos"
                              :loading="departamentosLoading"
                              dense
                              class="required"
                              filled
                              label="Departamento"
                              item-text="departamento"
                              item-value="id"
                              @change="obtenerMunicipiosPorDepartamento(datosResultado.departamentosId, 1)"
                              :no-data-text="
                                  departamentos != null
                                  ? 'Seleccione un departamento'
                                  : 'No se han encontradodepartamentos'
                              "
                              menu-props="auto"
                              >
                          </v-select>
                      </v-col>

                      <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="datosResultado.paisId===94">
                          <v-select
                              v-model="datosResultado.municipiosId"
                              :items="municipios"
                              :loading="municipiosLoading"
                              dense
                              class=""
                              filled
                              label="Municipio"
                              item-text="municipio"
                              item-value="id"
                              :no-data-text="
                                  municipios != null
                                  ? 'Seleccione un municipio'
                                  : 'No se han encontrado municipios'
                              "
                              menu-props="auto"
                              >
                          </v-select>
                      </v-col>

                  </v-row>



                  <v-row>
                  <!--inicio:: campo-->
                  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                      <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="totalHombresResultado"
                      label="Total hombres"
                      :rules="[
                          required('Total hombres'),
                          maxLength('Total hombres', 8),
                          
                      ]"
                      maxlength="8"
                      v-mask="'########'"
                      ></v-text-field>
                  </v-col>
                  <!--fin:: campo-->

                  <!--inicio:: campo-->
                  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                      <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="totalMujeresResultado"
                      label="Total mujeres"
                      :rules="[
                          required('Total mujeres'),
                          maxLength('Total mujeres', 8),
                          
                      ]"
                      maxlength="8"
                      v-mask="'########'"
                      ></v-text-field>
                  </v-col>
                  <!--fin:: campo-->


                  <!--inicio:: campo-->
                  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                      <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      :disabled="true"
                      v-model="totalGeneral"
                      label="Total general"
                      
                      maxlength="8"
                      v-mask="'########'"
                      ></v-text-field>
                  </v-col>
                  <!--fin:: campo-->
              </v-row>

                  <v-row>
                      <!--inicio:: campo -->
                      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                          <v-textarea
                          autocomplete="off"
                          class="required"
                          dense
                          filled
                          color="blue-grey lighten-2"
                          v-model="datosResultado.resumenActividad"
                          label="Resumen de la actividad"
                          rows="3"
                          :rules="[
                              required('resumen de la actividad'),
                              minLength('resumen de la actividad', 5),
                              maxLength('resumen de la actividad', 1000)
                          ]"
                          maxlength="1000"
                          ></v-textarea>
                      </v-col>
                      <!--fin:: campo-->


                      <!--inicio:: campo -->
                      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                          <v-textarea
                          autocomplete="off"
                          class=""
                          dense
                          filled
                          color="blue-grey lighten-2"
                          v-model="datosResultado.otroTipoBeneficio"
                          label="Otro tipo de beneficiario"
                          rows="3"
                          :rules="[
                              maxLength('Tipo de beneficiario', 1000)
                          ]"
                          maxlength="1000"
                          ></v-textarea>
                      </v-col>
                      <!--fin:: campo-->

                      <!--inicio:: campo -->
                      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                          <v-textarea
                          autocomplete="off"
                          class="required"
                          dense
                          filled
                          color="blue-grey lighten-2"
                          v-model="datosResultado.productoActividad"
                          label="Producto de la actividad"
                          rows="3"
                          :rules="[
                              required('Producto de la actividad'),
                              minLength('Producto de la actividad', 5),
                              maxLength('Producto de la actividad', 1000)
                          ]"
                          maxlength="1000"
                          ></v-textarea>
                      </v-col>
                      <!--fin:: campo-->

                  </v-row>

                  <v-row class="d-none">
                      <!--inicio:: campo -->
                      <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                          <v-autocomplete
                              dense
                              filled
                              v-model="estadoSeleccionado"
                              :items="estados"
                              class="required"
                              color="blue-grey lighten-2"
                              label="Estado de la actividad"
                              item-text="text"
                              return-object
                              item-value="id"
                              
                              menu-props="auto"
                              
                              >
                          </v-autocomplete>
                      </v-col>
                      <!--fin:: campo-->
                  </v-row>
                  
                  <v-row>
                      <v-col cols="12" md="12" sm="12" class="pt-0">
                          <v-spacer></v-spacer>
                          <v-btn
                          class="ma-0 white--text float-right"
                          medium
                          color="light-blue darken-2"
                          type="submit"
                          :loading="btnRegistroResultadoLoading"
                          :disabled="!validFormResultados"
                          >
                          <v-icon left>mdi-plus</v-icon> Guardar resultado
                          </v-btn>
                      </v-col>
              </v-row>
            </v-form>
        </v-card-text>
      </v-card>


      <!-- inicio:: seccion funcionarios participantes-->
      <v-card outlined v-if="accion===2 && formDetalleActividad && 1>1" class="mt-4">
        <v-card-text>

            <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                    <v-subheader class="text-h6 black--text">
                        Funcionarios Participantes | {{actividadSeleccionada}}
                    </v-subheader>
                </v-col>

            </v-row>

            <v-form ref="form" v-on:submit.prevent="registrarFuncionariosActividades" v-model="validFormFuncionarios">
              <v-row class="mt-4">
                
                <!--inicio:: campo-->
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        v-model="datosFuncionarioActividad.nombre"
                        label="Nombre del funcionario"
                        :rules="[
                            required('Nombre del funcionario'),
                            minLength('Nombre del funcionario', 5),
                            maxLength('Nombre del funcionario', 200)          
                        ]"
                        maxlength="200"
                  ></v-text-field>
                </v-col>
                <!--fin:: campo-->

                <!--inicio:: campo-->
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        v-model="datosFuncionarioActividad.instituciones"
                        label="Institución a la que pertenece el funcionario"
                        :rules="[
                            required('Nombre de la institución'),
                            minLength('Nombre de la institución', 5),
                            maxLength('Nombre de la institución', 250)          
                        ]"
                        maxlength="250"
                  ></v-text-field>
                </v-col>
                <!--fin:: campo-->

<!--                 <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-autocomplete
                    dense
                    filled
                    v-model="datosFuncionarioActividad.institucionesId"
                    :items="instituciones"
                    class="required"
                    color="blue-grey lighten-2"
                    :loading="dialogLoaderVisible"
                    label="Institución"
                    item-text="nombreInstitucion"
                    item-value="id"
                    :rules="[selectRequired('institución')]"
                    menu-props="auto"
                  >
                  </v-autocomplete>
                </v-col> -->


                <!--inicio:: campo-->
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-select
                    dense
                    filled
                    v-model="datosFuncionarioActividad.cargosId"
                    :items="cargos"
                    color="blue-grey lighten-2"
                    class="required"
                    label="Seleccionar Cargo"
                    item-text="nombreCargo"
                    item-value="id"
                    :rules="[selectRequired('cargo')]"
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>
                <!--fin:: campo-->   

                <!--inicio:: campo-->
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-select
                    dense
                    filled
                    v-model="datosFuncionarioActividad.rolesActorId"
                    :items="roles"
                    color="blue-grey lighten-2"
                    class="required"
                    label="Seleccionar Rol"
                    item-text="nombre"
                    item-value="id"
                    :rules="[selectRequired('rol')]"
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>
                <!--fin:: campo-->   
              </v-row>

            
              <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0">
                      <v-spacer></v-spacer>
                      <v-btn
                      class="ma-0 white--text float-right"
                      medium
                      color="light-blue darken-2"
                      type="submit"
                      :loading="btnRegistroFuncionarioLoading"
                      >
                      <v-icon left>mdi-plus</v-icon> Registrar funcionario
                      </v-btn>
                  </v-col>
              </v-row>

         
            </v-form>


            <v-row>

                    <v-divider></v-divider>

                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-1"
                            :headers="headersFuncionarios"
                            :items="funcionariosParticipantes"
                            :loading="tableFuncionariosLoading"
                            hide-default-footer
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.nombre }}</td>
                                    <td>{{ item.nombreInstitucion }}</td>
                                    <td>{{ item.nombreCargo }}</td>
                                    <td>{{ item.actor }}</td>
                                                        
                                    <!--<td>
                                        <v-btn v-if="item.estadosId==1"
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            color="blue-grey lighten-5"
                                            @click="descargarArchivo(item.path)"
                                            >
                                            <v-icon left>mdi-file-download-outline</v-icon> Descargar
                                        </v-btn>
                                        <v-btn v-if="item.estadosId==4"
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            color="blue-grey lighten-5"
                                            @click="eliminarArchivo(item.idTipo)"
                                            >
                                            <v-icon left>mdi-delete</v-icon> Eliminar
                                        </v-btn>

                                    </td>-->
                                </tr>
                            </template>

                            <template v-slot:no-data>
                                <p class="text-h7">
                                    <v-icon left>mdi-alert</v-icon> No existen funcionarios registrados
                                </p>
                            </template>

                        </v-data-table>

                    </v-col>
                    <!--fin:: tabla de documentos cargados -->
                </v-row>

        </v-card-text>
      </v-card>
      <!--fin:: seccion funcionarios participantes-->



      <!-- inicio:: seccion participantes-->
      <v-card outlined v-if="accion===2 && formDetalleActividad && 1>1" class="mt-4">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                    <v-subheader class="text-h6 black--text">
                        Participantes | {{actividadSeleccionada}}
                    </v-subheader>
                </v-col>
            </v-row>

            <v-form ref="form" v-on:submit.prevent="registrarParticipantesActividades" v-model="validFormParticipantes">
              <v-row class="mt-4">
                
                <!--inicio:: campo-->
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        v-model="datosParticipanteActividad.nombre"
                        label="Nombre del participante"
                        :rules="[
                            required('Nombre del participante'),
                            minLength('Nombre del participante', 5),
                            maxLength('Nombre del participante', 200)          
                        ]"
                        maxlength="200"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                 <!--inicio:: campo-->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    dense
                    filled
                    v-model="datosParticipanteActividad.etniasId"
                    :items="etnias"
                    color="blue-grey lighten-2"
                    class="required"
                    label="Seleccionar Etnia"
                    item-text="nombreEtnia"
                    item-value="id"
                    :rules="[selectRequired('etnia')]"
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>
                <!--fin:: campo-->

                 <!--inicio:: campo-->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    dense
                    filled
                    v-model="datosParticipanteActividad.genero"
                    :items="generos"
                    color="blue-grey lighten-2"
                    class="required"
                    label="Seleccionar Género"
                    item-text="nombreGenero"
                    item-value="id"
                    :rules="[selectRequired('etnia')]"
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>
                <!--fin:: campo-->


                <!--inicio:: campo-->
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        v-model="datosParticipanteActividad.institucion"
                        label="Institución a la que pertenece el participante"
                        :rules="[
                            required('Nombre de la institución'),
                            minLength('Nombre de la institución', 5),
                            maxLength('Nombre de la institución', 200)          
                        ]"
                        maxlength="200"
                  ></v-text-field>
                </v-col>
                <!--fin:: campo-->

                <!-- <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-autocomplete
                    dense
                    filled
                    v-model="datosParticipanteActividad.institucionesId"
                    :items="instituciones"
                    class="required"
                    color="blue-grey lighten-2"
                    :loading="dialogLoaderVisible"
                    label="Institución"
                    item-text="nombreInstitucion"
                    item-value="id"
                    :rules="[selectRequired('institución')]"
                    menu-props="auto"
                  >
                  </v-autocomplete>
                </v-col> -->


                <!--inicio:: campo-->
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-select
                    dense
                    filled
                    v-model="datosParticipanteActividad.cargosId"
                    :items="cargos"
                    color="blue-grey lighten-2"
                    class="required"
                    label="Seleccionar Cargo"
                    item-text="nombreCargo"
                    item-value="id"
                    :rules="[selectRequired('cargo')]"
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>
                <!--fin:: campo-->

                <!--inicio:: campo-->
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        v-model="datosParticipanteActividad.area"
                        label="Área"
                        :rules="[
                            required('área'),
                            minLength('área', 3),
                            maxLength('área', 100)          
                        ]"
                        maxlength="200"
                  ></v-text-field>
                </v-col>   

                <!--inicio:: campo-->
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-select
                    dense
                    filled
                    v-model="datosParticipanteActividad.rangosEdadId"
                    :items="rangosEdad"
                    color="blue-grey lighten-2"
                    class="required"
                    label="Seleccionar rango de edad"
                    item-text="rango"
                    item-value="id"
                    :rules="[selectRequired('rango de edad')]"
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>
                <!--fin:: campo-->   

              </v-row>

              <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0">
                      <v-spacer></v-spacer>
                      <v-btn
                      class="ma-0 white--text float-right"
                      medium
                      color="light-blue darken-2"
                      type="submit"
                      :loading="btnRegistroParticipanteLoading"
                      >
                      <v-icon left>mdi-plus</v-icon> Registrar participante
                      </v-btn>
                  </v-col>
              </v-row>


            </v-form>

            <v-row>

                    <v-divider></v-divider>

                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-1"
                            :headers="headersParticipantesActividad"
                            :items="participantesActividad"
                            :loading="tableParticipantesLoading"
                            hide-default-footer
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.nombre }}</td>
                                    <td>{{ item.nombreEtnia }}</td>
                                    <td>{{ item.nombreInstitucion }}</td>
                                    <td>{{ item.area }}</td>
                                    <td>{{ item.nombreCargo }}</td>
                                   <!--  <td>{{ item.actor }}</td> -->
                                                        
                                    <!--<td>
                                        <v-btn v-if="item.estadosId==1"
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            color="blue-grey lighten-5"
                                            @click="descargarArchivo(item.path)"
                                            >
                                            <v-icon left>mdi-file-download-outline</v-icon> Descargar
                                        </v-btn>
                                        <v-btn v-if="item.estadosId==4"
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            color="blue-grey lighten-5"
                                            @click="eliminarArchivo(item.idTipo)"
                                            >
                                            <v-icon left>mdi-delete</v-icon> Eliminar
                                        </v-btn>

                                    </td>-->
                                </tr>
                            </template>

                            <template v-slot:no-data>
                                <p class="text-h7">
                                    <v-icon left>mdi-alert</v-icon> No existen participantes registrados
                                </p>
                            </template>

                        </v-data-table>

                    </v-col>
                    <!--fin:: tabla de documentos cargados -->
                </v-row>

            

        </v-card-text>
      </v-card>
      <!--fin:: seccion participantes-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</div>
</template>
<script>
import Vue from 'vue';
import moment from "moment";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";

import validations from "@/core/untils/validations.js";
import { OBTENER_ACTIVIDADES, ELIMINAR_ACTIVIDAD, REGISTRAR_ACTIVIDAD } from "@/core/services/store/negociaciones/actividadiniciativa.module";
import { REGISTRAR_PRODUCTO_ACTIVIDAD, OBTENER_PRODUCTOS_ACTIVIDAD, ELIMINAR_PRODUCTO_ACTIVIDAD } from "@/core/services/store/negociaciones/iniciativas/productosactividades/productoactividad.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
//import { OBTENER_PAISES } from "@/core/services/store/catalogos/paises/pais.module";
import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
import { OBTENER_MUNICIPIOS_DEPARTAMENTO } from "@/core/services/store/catalogos/municipios/municipio.module";
import { OBTENER_RESULTADO_ACTIVIDAD_SEGUIMIENTO, REGISTRAR_RESULTADO_ACTIVIDAD_SEGUIMIENTO, ACTUALIZAR_RESULTADO_ACTIVIDAD_SEGUIMIENTO, REGISTRAR_FUNCIONARIOS_ACTIVIDAD_SEGUIMIENTO,OBTENER_FUNCIONARIOS_ACTIVIDAD_SEGUIMIENTO, 
         REGISTRAR_PARTICIPANTES_ACTIVIDAD_SEGUIMIENTO, OBTENER_PARTICIPANTES_ACTIVIDAD_SEGUIMIENTO } from "@/core/services/store/negociaciones/iniciativas/seguimientos/seguimiento.module";
import { OBTENER_INSTITUCIONES } from "@/core/services/store/instituciones/institucion.module";

moment.locale('es');
Vue.filter("formatDate", function(value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    }
});


export default {
  name: "SeccionActividadesSeguimiento",
  components: {
    SnackAlert
  },
  props: ['iniciativaId', 'seguimientoId', 'negociacionId'], //Representa el id de la iniciativa
  data() {
    return {
      validformDetalleActividad: false,
      accion: 1,
      validFormResultados: false,
      validFormFuncionarios: false,
      btnRegistroResultadoLoading: false,
      
      validFormParticipantes: false,
      btnRegistroParticipanteLoading: false,
      tableParticipantesLoading: false,
      participantesActividad: [],
      skeletonLoading: false,
      skeletonResultadoLoading: false,
      tiposActividad: [],
      tableProductosLoading: false,
      sectores: [],
      paises: [],
      actividades: [],
      productos: [],
      departamentos: [],
      municipios: [],
      formatos: [{id: 1, text: 'Presencial'}, {id:2, text: 'Virtual'}],
      formatoSeleccionado: {},
      estadoSeleccionado:{},
      estados: [{id: 1, text: 'Estado 1'}, {id:2, text: 'Estado 2'}, {id:3, text: 'Estado 3'}],
      btnRegistroLoading:false,
      btnRegistroProductoLoading:false,
      btnEliminarActividadDisabled: false,
      btnEliminarProductoDisabled: false,
      btnRegistroFuncionarioLoading: false,
      formDetalleActividad: false,
      fechaPrevista:"",
      actividadSeleccionada: "",
      departamentosActividadLoading: false,
      municipiosLoading: false,
      datosItem: {
        fechaPrevista: "",
        descripcionActividad: "",
        iniciativaId: this.iniciativaId,
        tipoActividadId: 0,
        paisId: 0,
        estadosId: 1,
        sectoresId: 0,
        usuarioCreacion: "admin"
      },
      datosProducto:{
        producto:"",
        estadosId:1,
        actividadesIniciativaId:0,
        usuarioCreacion: "admin"
      },
      inicioRealizacion: "",
      finalizaRealizacion: "",
      datosResultado: {
        id: 0,
        seguimientoId: 0,
        actividadesIniciativaId: 0,
        inicioRealizacion: "",
        finalizaRealizacion: "",
        paisId: 0,
        formato: "",
        municipiosId: 0,
        departamentosId: 0,
        hombres: 0,
        mujeres: 0,
        resumenActividad: "",
        otroTipoBeneficio: "",
        productoActividad: "",
        estadosId: 1,
        totalGeneral: "",
        usuarioCreacion: "admin",
      },
      participantes:[],
      funcionariosParticipantes: [],
      tableFuncionariosLoading: false,
      datosFuncionarioActividad: {
         id: 0,
         nombre: "",
         //institucionesId: 0,
         instituciones: "",
         cargosId: 0,
         rolesActorId: 0,
         seguimientoActividadesId: 0,
         estadosId: 1,
         usuarioCreacion: "admin",
      },
      rangosEdad: [],
      datosParticipanteActividad: {
        nombre: "",
        cargosId: 0,
        //institucionesId: 0,
        institucion: "",
        etniasId: 0,
        genero: "",
        rangosEdadId: 0,
        seguimientoActividadesId: 0,
        area: "",
        estadosId: 1,
        usuarioCreacion: "a"
      },
      seguimientoActividadesId: 0,
      roles: [],
      instituciones:[],
      cargos: [],
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 0,
      },
      etnias:[],
      generos: [{id: "F", nombreGenero: "Femenino"},{id: "M", nombreGenero: "Masculino"}],
      totalHombresResultado: "",
      totalMujeresResultado: "",
      dialogActividad: false,
      mostrarRegistroActividad:false,
      datosActividad:{
        fechaPrevista: "",
        descripcionActividad: "",
        iniciativaId: this.iniciativaId,
        tipoActividadId: 0,
        paisId: 0,
        estadosId: 1,
        //sectoresId: 0,
        usuarioCreacion: "admin"
      },
      validFormActividad:false,
      //tiposActividad: [],
      //paises: [],
      ...validations
    };
  },


  methods: {


    resetItems(){
      this.mostrarRegistroActividad = false;
      this.formDetalleActividad=false;
       this.fechaPrevista = "";
       this.datosItem= {
        fechaPrevista: "",
        descripcionActividad: "",
        iniciativaId: this.iniciativaId,
        tipoActividadId: 0,
        paisId: 0,
        estadosId: 1,
        sectoresId: 0,
        usuarioCreacion: "admin"
      };

    },

    resetFormActividad(){
      this.datosActividad={
        fechaPrevista: "",
        descripcionActividad: "",
        iniciativaId: this.iniciativaId,
        tipoActividadId: 0,
        paisId: 0,
        estadosId: 1,
        //sectoresId: 0,
        usuarioCreacion: "admin"
      };
    },

    resetDatosFuncionarios() {

      this.datosFuncionarioActividad= {
         id: 0,
         nombre: "",
         //institucionesId: 0,
         instituciones: "",
         cargosId: 0,
         rolesActorId: 0,
         seguimientoActividadesId: this.seguimientoActividadesId,
         estadosId: 1,
         usuarioCreacion: "admin",
      }
    },

     resetDatosParticipanteActividad(){
      this.datosParticipanteActividad= {
        nombre: "",
        cargosId: 0,
        // institucionesId: 0,
        institucion: "",
        etniasId: 0,
        genero: "",
        rangosEdadId: 0,
        seguimientoActividadesId: 0,
        area: "",
        estadosId: 1,
        usuarioCreacion: "a",
      };
    },



    //Obtener el listado de actividades segun la iniciativa
    async obtenerActividades(idIniciativa){
      
      this.actividades = [];
      this.skeletonLoading = true;
    
      this.$store
          .dispatch(OBTENER_ACTIVIDADES, {estadoId:0, iniciativaId: idIniciativa})
          .then(res => {
              if(res.status===200){
                this.actividades =res.data;
              }
              this.skeletonLoading = false;
          })
          .catch(() => {
            this.actividades = [];
              this.skeletonLoading = false;
          });

    },


        //Obtener los departamentos
        async obtenerDepartamentos() {
            this.departamentosLoading=true;
            this.departamentosActividadLoading=true;
            this.departamentos = [];
            this.departamentosActividad = [];
            await this.$store
            .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                    this.departamentos = res.data;
                    this.departamentosActividad = res.data;

                }
                this.departamentosLoading=false;
                this.departamentosActividadLoading=false;
            })
            .catch(() => {
                this.departamentos = [];
                this.departamentosActividad = [];
                this.departamentosLoading=false;
                this.departamentosActividadLoading=false;
            });
        
        },

        //Obtener municipios por departamento
        async obtenerMunicipiosPorDepartamento(departamentosId, tipo){
            
            this.municipiosLoading=true;
            this.datosItem.municipiosId=null;
            let deptoId = 0;
            tipo=== 1 ? this.municipios = [] : this.municipiosActividad = [];
            deptoId = departamentosId;

            await this.$store
            .dispatch(OBTENER_MUNICIPIOS_DEPARTAMENTO, deptoId) //1 representa al país 1 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                    tipo=== 1 ? this.municipios = res.data : this.municipiosActividad = res.data;
                }
                tipo=== 1 ? this.municipiosLoading = false : this.municipiosActividadLoading = false;
            })
            .catch(() => {
                tipo=== 1 ? this.municipios = [] : this.municipiosActividad = [];
                tipo=== 1 ? this.municipiosLoading = false : this.municipiosActividadLoading = false;
                
            });
        },


    async obtenerTiposActividad() {
      this.tiposActividad = [];
      
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TipoActividad/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.tiposActividad = res.data;
          }
         // this.skeletonLoading=false;
        })
        .catch(() => {
            this.tiposActividad = [];
           // this.skeletonLoading=false;
        });
    },

    async obtenerSectores() {
      this.sectores = [];
       await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Sectores/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.sectores = res.data;
          }
         // this.skeletonLoading=false;
        })
        .catch(() => {
            this.sectores = [];
           // this.skeletonLoading=false;
        });
    },

    /* async obtenerPaises() {
      this.paises = [];
      
      await this.$store
        .dispatch(OBTENER_PAISES)
        .then(res => {
          if (res.status === 200) {
            this.paises = res.data;
          }
         // this.skeletonLoading=false;
        })
        .catch(() => {
            this.paises = [];
           // this.skeletonLoading=false;
        });
      
    }, */
    async obtenerPaises() {
      this.paises = [];
      
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Pais/Actores/${this.negociacionId}`})
        .then(res => {
          if (res.status === 200) {
            this.paises = res.data;
          }
         // this.skeletonLoading=false;
        })
        .catch(() => {
            this.paises = [];
           // this.skeletonLoading=false;
        });
      
    },



    //Obtener los roles para registro
    async obtenerRoles() {
      /*this.roles = [
        { id: 1, nombreRol: "Rol 1" },
        { id: 2, nombreRol: "Rol 2" },
        { id: 3, nombreRol: "Rol 3" }
      ];*/

       this.roles=[];

       await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'RolesActores/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.roles = res.data;
          }
        })
        .catch(() => {
            this.roles = [];
        });
    },


    //Obtener las instituciones
    async obtenerInstituciones() {
      this.instituciones = [];
      

      this.$store
        .dispatch(OBTENER_INSTITUCIONES)
        .then(res => {
          if (res.status === 200) {
            this.instituciones = res.data;
          }
        })
        .catch(() => {
        
        });
    },


    //Obtener los cargos
    async obtenerCargos() {
       this.cargos=[];

       await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Cargos/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.cargos = res.data;
          }
        })
        .catch(() => {
            this.cargos = [];
        });
    },


    //Mostrar formulario de registro de productos para la actividad
    mostrarFormularioResultados(id, actividad){
      /*this.totalHombresResultado=0;
      this.totalMujeresResultado=0;*/
      //this.datosResultado.totalGeneral=(parseInt(this.datosResultado.hombres) + parseInt(this.datosResultado.mujeres)).toString();
      this.seguimientoActividadesId = id;
      this.datosProducto.actividadesIniciativaId=id;
      this.actividadSeleccionada = actividad;
      this.formDetalleActividad = true;
      this.totalHombresResultado="";
      this.totalMujeresResultado="";
      this.obtenerResultadoActividad(id);
      //this.obtenerProductos(id);
    },
    
    //Obtener información del resultado de la actividad
    async obtenerResultadoActividad(idActividad){
      this.skeletonResultadoLoading = true;     
      this.datosResultado = {
        id: 0,
        seguimientoId: 0,
        actividadesIniciativaId: idActividad,
        inicioRealizacion: "",
        finalizaRealizacion: "",
        paisId: 0,
        formato: "",
        municipiosId: 0,
        departamentosId: 0,
        hombres: 0,
        mujeres: 0,
        resumenActividad: "",
        otroTipoBeneficio: "",
        productoActividad: "",
        estadosId: 1,
        usuarioCreacion: "admin",
        totalGeneral: "",
      };
       await this.$store
        .dispatch(OBTENER_RESULTADO_ACTIVIDAD_SEGUIMIENTO, {id: this.seguimientoId, idActividad: idActividad })
        .then(res => {
          if (res.status === 200) {
            this.resetDatosFuncionarios();
            this.datosResultado = res.data;
            // this.seguimientoActividadesId = this.datosResultado.id;
            this.inicioRealizacion = this.datosResultado.inicioRealizacion;
            this.finalizaRealizacion = this.datosResultado.finalizaRealizacion;
            this.totalHombresResultado = this.datosResultado.hombres.toString(); 
            this.totalMujeresResultado = this.datosResultado.mujeres.toString();
            
            this.datosResultado.totalGeneral = (parseInt(this.datosResultado.hombres) + parseInt(this.datosResultado.mujeres)).toString();
            //console.log( this.datosResultado.totalGeneral)
            this.obtenerMunicipiosPorDepartamento(this.datosResultado.departamentosId, 1);
            if(this.datosResultado.formato === "Presencial"){
              this.formatoSeleccionado.id = 1;
              this.formatoSeleccionado.text = "Presencial";
            } else {

               this.formatoSeleccionado.id = 2;
               this.formatoSeleccionado.text = "Virtual";
            }

            //this.obtenerFuncionariosActividades();
            //this.obtenerParticipantesActividad();

            
            this.accion = 2;
           // this.datosResultado.formato === "Presencial" ? this.formatoSeleccionado.id = 1 : this.formatoSeleccionado.id = 2;

 
          } else {
            this.accion = 1;
          }
          this.skeletonResultadoLoading = false;
         // this.skeletonLoading=false;
        })
        .catch(() => {
            this.datosResultado = {};
            this.skeletonResultadoLoading = false;
           // this.skeletonLoading=false;
        });
    },

    //Registrar un resultado de actividad
    async registrarResultado() {
      
      this.btnRegistroResultadoLoading = true;
      this.datosResultado.seguimientoId = this.seguimientoId;
      this.datosResultado.formato = this.formatoSeleccionado.text;
      this.datosResultado.inicioRealizacion = moment(this.inicioRealizacion, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.datosResultado.finalizaRealizacion = moment(this.finalizaRealizacion, "DD-MM-YYYY").format("YYYY-MM-DD");

      if(this.datosResultado.paisId != 94) {
        this.datosResultado.departamentosId = null;
        this.datosResultado.municipiosId = null;
      }

      this.datosResultado.mujeres = parseInt(this.totalHombresResultado);
      this.datosResultado.hombres = parseInt(this.totalMujeresResultado);
      this.datosResultado.totalGeneral = parseInt(this.totalGeneral);


      

      if(this.accion===1){
          //this.datosRiesgo.proyectoCNSId = this.id;
          await this.$store
          .dispatch(REGISTRAR_RESULTADO_ACTIVIDAD_SEGUIMIENTO, {datos: this.datosResultado})
          .then(res => {
              //console.log(res)
              if(res.status===200){
                  // this.obtnenerItems();
                  // this.dialog=false;
                  this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                  this.datosResultado.id = res.data;
                  
                  this.btnRegistroResultadoLoading=false;
                  this.accion = 2;
                  
                  
              } else {
                  this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
              }
              this.btnRegistroResultadoLoading = false;
          })
          .catch(error => {
              //console.log(error)
              this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
              this.btnRegistroResultadoLoading=false;
          });
      }
      else {
        //this.datosRiesgo.proyectoCNSId = this.id;
          await this.$store
          .dispatch(ACTUALIZAR_RESULTADO_ACTIVIDAD_SEGUIMIENTO, {datosItem: this.datosResultado, id: this.datosResultado.id})
          .then(res => {
              //console.log(res)
              if(res.status===200){
                  // this.obtnenerItems();
                  // this.dialog=false;
                  this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                  
                  this.btnRegistroResultadoLoading=false;
                  this.accion=2;
                  
                  
              } else {
                  this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
              }
              this.btnRegistroResultadoLoading = false;
          })
          .catch(error => {
              //console.log(error)
              this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
              this.btnRegistroResultadoLoading=false;
          });
      }
      
    },

    //Eliminar una actividad
    async eliminarActividad(id){
       this.btnEliminarActividadDisabled = true;
     
      this.$store
          .dispatch(ELIMINAR_ACTIVIDAD, id)
          .then(res => {
            if(res.status ===200){
              this.productos = res.data;
               this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
               this.obtenerActividades(this.datosItem.iniciativaId);
            } else {
               this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
            }

            this.btnEliminarActividadDisabled = false;
            this.tableProductosLoading = false;
          })
          .catch(error => {
            this.btnEliminarActividadDisabled = false;
              this.tableProductosLoading = false;
               this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
          });
    },


    async registrarFuncionariosActividades(){
      this.datosFuncionarioActividad.seguimientoActividadesId = this.seguimientoId;
      this.datosFuncionarioActividad.estadosId = 1;
      this.datosFuncionarioActividad.usuarioCreacion = "admin";
      
      this.btnRegistroFuncionarioLoading = true;
      await this.$store
          .dispatch(REGISTRAR_FUNCIONARIOS_ACTIVIDAD_SEGUIMIENTO, {datos: this.datosFuncionarioActividad})
          .then(res => {
              //console.log(res)
              if(res.status===200){
                  // this.obtnenerItems();
                  // this.dialog=false;
                  this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                  
                  this.btnRegistroResultadoLoading=false;
                  this.obtenerFuncionariosActividades();
                  this.resetDatosFuncionarios();
                  
              } else {
                  this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
              }
              this.btnRegistroFuncionarioLoading = false;
          })
          .catch(error => {
              //console.log(error)
              this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
              this.btnRegistroFuncionarioLoading=false;
          });
    },

    async obtenerFuncionariosActividades(){
       this.tableFuncionariosLoading = true;
       this.funcionariosParticipantes = [];
       await this.$store
        .dispatch(OBTENER_FUNCIONARIOS_ACTIVIDAD_SEGUIMIENTO, {estadoId: 1, seguimientoActividadesId: this.seguimientoId })
        .then(res => {
          if (res.status === 200) {
          
            this.funcionariosParticipantes = res.data;
 
          } 
          this.tableFuncionariosLoading = false;
         // this.skeletonLoading=false;
        })
        .catch(() => {
            this.funcionariosParticipantes = [];
            this.tableFuncionariosLoading = false;
           // this.skeletonLoading=false;
        });
    },

    async obtenerRangosEdad() {
      this.rangosEdad = [];
      
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'RangosEdad/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.rangosEdad = res.data;
          }
         // this.skeletonLoading=false;
        })
        .catch(() => {
            this.rangosEdad = [];
           // this.skeletonLoading=false;
        });
    },

    
    async obtenerEtnias() {
      this.etnias = [];
      
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Etnias/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.etnias = res.data;
          }
         // this.skeletonLoading=false;
        })
        .catch(() => {
            this.etnias = [];
           // this.skeletonLoading=false;
        });
    },


    //Registrar participante actividad
    async registrarParticipantesActividades(){
      this.btnRegistroParticipanteLoading = true;
      this.datosParticipanteActividad.seguimientoActividadesId =this.seguimientoId;

      await this.$store
          .dispatch(REGISTRAR_PARTICIPANTES_ACTIVIDAD_SEGUIMIENTO, {datos: this.datosParticipanteActividad})
          .then(res => {
              //console.log(res)
              if(res.status===200){
                  // this.obtnenerItems();
                  // this.dialog=false;
                  this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                  
                 
                  this.obtenerParticipantesActividad();
                  this.resetDatosParticipanteActividad();
                  
              } else {
                  this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
              }
               this.btnRegistroParticipanteLoading=false;
          })
          .catch(error => {
              //console.log(error)
              this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
              this.btnRegistroParticipanteLoading=false;
          });

    },

   
    async obtenerParticipantesActividad(){
       this.tableParticipantesLoading = true;
       this.participantesActividad = [];
       await this.$store
        .dispatch(OBTENER_PARTICIPANTES_ACTIVIDAD_SEGUIMIENTO, {estadoId: 1, seguimientoActividadesId: this.seguimientoId })
        .then(res => {
          if (res.status === 200) {
          
            this.participantesActividad = res.data;
 
          } 
          this.tableParticipantesLoading = false;
         // this.skeletonLoading=false;
        })
        .catch(() => {
            this.participantesActividad = [];
            this.tableParticipantesLoading = false;
           // this.skeletonLoading=false;
        });


    },
    


   

    //Registrar producto de la actividad
    async registrarProducto(){

      this.btnRegistroProductoLoading= true;
      
      await this.$store
        .dispatch(REGISTRAR_PRODUCTO_ACTIVIDAD, this.datosProducto)
        .then(res => {
            //console.log(res)
            if(res.status===200){
                // this.obtnenerItems();
                // this.dialog=false;
                this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                
              //  this.switchItemEstado = true;
                //this.datosRiesgo.detalleRiesgo = '';
                //this.resetItems();
                this.obtenerProductos(this.datosProducto.actividadesIniciativaId);
                this.btnRegistroProductoLoading=false;
                //this.obtenerActividades(this.iniciativaId);
            } else {
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
            }
            this.btnRegistroProductoLoading=false;
        })
        .catch(error => {
            //console.log(error)
            this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
            this.btnRegistroProductoLoading=false;
        });

    },


    //Obtener productos de una actividad
    async obtenerProductos(actividadId){
      this.tableProductosLoading=true;
      this.$store
          .dispatch(OBTENER_PRODUCTOS_ACTIVIDAD, {estadoId:1, actividadId: actividadId})
          .then(res => {
            if(res.status ===200){
              this.productos = res.data;
            }
            this.tableProductosLoading = false;
          })
          .catch(() => {
              this.tableProductosLoading = false;
          });


    },

    //Eliminar un producto
    async eliminarProducto(id){
      this.btnEliminarProductoDisabled = true;
      this.tableProductosLoading=true;
      this.$store
          .dispatch(ELIMINAR_PRODUCTO_ACTIVIDAD, id)
          .then(res => {
            if(res.status ===200){
              this.productos = res.data;
               this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
               this.obtenerProductos(this.datosProducto.actividadesIniciativaId);
            } else {
               this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
            }

            this.btnEliminarProductoDisabled = false;
            this.tableProductosLoading = false;
          })
          .catch(error => {
            this.btnEliminarProductoDisabled = false;
              this.tableProductosLoading = false;
               this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
          });
    },


    mostrarDialogActividad(){
     // this.obtenerTiposActividad();
     // this.obtenerPaises();
      this.mostrarRegistroActividad = true;
    },






    async agregarActividad() {

    this.datosActividad.fechaPrevista = moment(this.fechaPrevista, "DD-MM-YYYY").format("YYYY-MM-DD");

    this.btnRegistroActividadLoading=true;
      //this.datosRiesgo.proyectoCNSId = this.id;
      await this.$store
      .dispatch(REGISTRAR_ACTIVIDAD, this.datosActividad)
      .then(res => {
          //console.log(res)
          if(res.status===200){
              // this.obtnenerItems();
              // this.dialog=false;
              this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
              
            //  this.switchItemEstado = true;
              //this.datosRiesgo.detalleRiesgo = '';
              this.resetFormActividad();
              this.mostrarRegistroActividad = false;
              this.btnRegistroActividadLoading=false;
              this.obtenerActividades(this.iniciativaId);
          } else {
              this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
          }
          this.btnRegistroActividadLoading=false;
      })
      .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
          this.btnRegistroActividadLoading=false;
      });
    },


  },

  created() {
    this.obtenerTiposActividad();
    this.obtenerSectores();
    this.obtenerPaises();
    this.obtenerDepartamentos();

    this.obtenerActividades(this.iniciativaId);
    this.obtenerRoles();
    this.obtenerInstituciones();
    this.obtenerCargos();
    this.obtenerRangosEdad();
    this.obtenerEtnias();
  },

   /*watch:{

    totalGeneral : function(){
      this.datosResultado.totalGeneral = this.totalGeneral;
      console.log(this.datosResultado.totalGeneral)
      console.log(this.totalGeneral)
    },
     totalHombresResultado : function(){
            this.totalHombresResultado.length > 0 && this.totalMujeresResultado.length > 0 ? this.datosResultado.totalGeneral = ((parseInt(this.totalHombresResultado)) + (parseInt(this.totalMujeresResultado))).toString() : this.datosResultado.totalGeneral ="";
        },

      totalMujeresResultado : function() {
            this.totalHombresResultado.length > 0 && this.totalMujeresResultado.length > 0 ? this.datosResultado.totalGeneral = ((parseInt(this.totalHombresResultado)) + (parseInt(this.totalMujeresResultado))).toString() : this.datosResultado.totalGeneral ="";
        },
  }, */

  computed: {

    totalGeneral : function(){
          let total = "";
          this.totalHombresResultado.length > 0 && this.totalMujeresResultado.length > 0 ? total = ((parseInt(this.totalHombresResultado)) + (parseInt(this.totalMujeresResultado))).toString() : total ="";
          //this.datosResultado.totalGeneral = total;
          return  total;
      },


    headersActividades() {
      return [
        {
          text: "Descripcion",
          align: "start",
          sortable: false,
          value: "descripcionActividad"
        },
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "tipo"
        },
        {
          text: "Sector",
          align: "start",
          sortable: true,
          value: "sector"
        },
        {
          text: "Fecha prevista",
          align: "start",
          sortable: true,
          value: "fechaPrevista"
        },
        {
          text: "Lugar",
          align: "start",
          sortable: true,
          value: "pais"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

    headersProductos(){
      return [
         {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "producto"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ]
    },
    headersFuncionarios() {
      return [
         {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre"
        },
        {
          text: "Institución",
          align: "start",
          sortable: true,
          value: "institucion"
        },
        {
          text: "Cargo",
          align: "start",
          sortable: true,
          value: "cargo"
        },
        {
          text: "Rol",
          align: "start",
          sortable: true,
          value: "rol"
        },
        
        /*{
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }*/
      ]
    },

    headersParticipantesActividad() {
      return [
         {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre"
        },
        {
          text: "Etnia",
          align: "start",
          sortable: true,
          value: "nombreEtnia"
        },
        {
          text: "Institución",
          align: "start",
          sortable: true,
          value: "institucion"
        },
        {
          text: "Area",
          align: "start",
          sortable: true,
          value: "area"
        },
        {
          text: "Cargo",
          align: "start",
          sortable: true,
          value: "cargo"
        },
        
        
        /*{
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }*/
      ]
    }
  }
};
</script>
<style lang=""></style>
