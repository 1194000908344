<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-if="!skeletonLoading">

        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-subheader class="text-h6 black--text">
                                Ejecución financiera
                            </v-subheader>
                    
                            <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>


                <v-form 
                        ref="formProyeccionDesembolsos"
                        v-on:submit.prevent="registrarItem"
                        v-model="validForm"
                        >

                    <v-row class="mt-4">
                        <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="porcentajeAvanceEFIN"
                                label="Porcentaje de avance"
                                suffix="%"
                                :rules="[
                                    required('porcentaje de avance'),
                                    minLength('porcentaje de avance', 1),
                                    maxLength('porcentaje de avance', 3),
                                    maxNumber('porcentaje de avance', 100)
                                ]"
                                maxlength="3"
                                v-mask="'###'"
                                ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="costosEjecutadosQEFIN"
                                label="Costos ejecutados en quetzales"
                                suffix="GTQ"
                                :rules="[
                                    required('costos ejecutados en quetzales'),
                                    minLength('costos ejecutados en quetzales', 1),
                                    maxLength('costos ejecutados en quetzales', 14),
                                    decimals('costos ejecutados en quetzales')
                                ]"
                                maxlength="14"
                                
                                ></v-text-field>
                        </v-col>


                        <!-- <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="costosEjecutadosDEFIN"
                                label="Costos ejecutados en dólares"
                                suffix="USDM"
                                :rules="[
                                    required('costos ejecutados en dólares'),
                                    minLength('costos ejecutados en dólares', 1),
                                    maxLength('costos ejecutados en dólares', 14),
                                    decimals('costos ejecutados en dólares')
                                ]"
                                maxlength="14"
                                
                                ></v-text-field>
                        </v-col> -->


                        <!--inicio:: campo-->
                        <!-- <v-col cols="12" md="6" sm="12" class="pb-0 pt-0">
                            <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="fechaTipoCambio"
                            label="Fecha de tipo de cambio"
                            :rules="[
                                required('Fecha de tipo de cambio'),
                                minLength('Fecha de tipo de cambio', 8),
                                dateFormat('Fecha de tipo de cambio')
                            ]"
                            maxlength="50"
                            v-mask="'##/##/####'"
                            ></v-text-field>
                        </v-col> -->
                        <!--fin:: campo-->


                        <!-- <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="tipoCambioEFIN"
                                label="Tipo de cambio"
                                :rules="[
                                    required('Tipo de cambio'),
                                    minLength('Tipo de cambio', 1),
                                    maxLength('Tipo de cambio', 14),
                                    decimals('Tipo de cambio')
                                ]"
                                maxlength="14"
                                
                                ></v-text-field>
                        </v-col> -->
                    </v-row>


                    <v-row>

                            <v-col cols="12" md="12" sm="12" class="pt-0">
                                    
                                <v-btn
                                    color="light-blue-502"
                                    class="white--text mb-2 float-right"
                                    type="submit"
                                    large
                                    :elevation="0"
                                    :disabled="!validForm"
                                    :loading="btnRegistroLoading"
                                >
                                    {{btnRegistroAvanceText}}
                                </v-btn>
                            </v-col>


                        </v-row>
                </v-form>
            </v-card-text>
        </v-card>


        <!--inicio:: carga de imagenes ejecucion fisica-->
        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-form
                    ref="form"
                    v-on:submit.prevent="registrarAporte"
                    v-model="validFormAporte"
                >
                    <v-row>

                        <v-col cols="12" md="12" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                            <v-subheader class="text-h6 black--text">
                                Aporte de actores
                            </v-subheader>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12">
                            <v-alert
                                dense
                                border="left"
                                type="warning"
                                >
                                Para verificar el tipo de cambio oficial en el <strong>banco de Guatemala</strong> haga click <a href="https://www.banguat.gob.gt/tipo_cambio/" target="_blank" class="white--text">aquí</a>
                            </v-alert>
                        </v-col>
                    </v-row>

                    <v-row>

                        <!--inicio:: campo-->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-autocomplete
                                dense
                                filled
                                v-model="datosAporte.paisId"
                                :items="paises"
                                :loading="ddPaisesLoading"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Pais"
                                item-text="nombre"
                                item-value="id"
                                :rules="[selectRequired('País')]"
                                menu-props="auto"
                                >
                            </v-autocomplete>
                        </v-col>
                        <!--fin:: campo-->

                        <!--inicio:: campo-->
                        <!-- <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosAporte.rolesActorId"
                                :items="roles"
                                color="blue-grey lighten-2"
                                class="required"
                                label="Seleccionar Rol"
                                item-text="nombre"
                                item-value="id"
                                :rules="[selectRequired('Rol')]"
                                menu-props="auto"
                            >
                            </v-select>
                        </v-col> -->
                        <!--fin:: campo-->


                        <!--inicio:: campo-->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosAporte.tipoAporteId"
                                :items="tiposAporte"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Tipo de aporte"
                                item-text="aporte"
                                item-value="id"
                                menu-props="auto"
                                :rules="[
                                    selectRequired('tipo de aporte'),
                                ]"
                            >
                            </v-select>
                        </v-col>
                        <!--fin:: campo-->


                         <!--inicio:: campo-->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="datosAporte.tipoAporteId === 1">
                            <v-select
                                dense
                                filled
                                v-model="fuenteSeleccionada"
                                :items="fuentes"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Fuente"
                                item-text="fuente"
                                item-value="id"
                                menu-props="auto"
                                return-object
                                :rules="[
                                    datosAporte.tipoAporteId === 1 ? selectRequired('tipo de aporte') : true,
                                ]"
                            >
                            </v-select>
                        </v-col>
                        <!--fin:: campo-->

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="montoMonedaDolares"
                                label="Costos ejecutados en dólares"
                                suffix="USDM"
                                :rules="[
                                    required('costos ejecutados en dólares'),
                                    minLength('costos ejecutados en dólares', 1),
                                    maxLength('costos ejecutados en dólares', 14),
                                    decimals('costos ejecutados en dólares')
                                ]"
                                maxlength="14"
                                
                                ></v-text-field>
                        </v-col>


                        <!--inicio:: campo-->
                        <v-col cols="12" md="6" sm="12" class="pb-0 pt-0">
                            <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="fechaTipoCambio"
                            label="Fecha de tipo de cambio"
                            :rules="[
                                required('Fecha de tipo de cambio'),
                                minLength('Fecha de tipo de cambio', 8),
                                dateFormat('Fecha de tipo de cambio')
                            ]"
                            maxlength="50"
                            v-mask="'##/##/####'"
                            ></v-text-field>
                        </v-col>
                        <!--fin:: campo-->





                        <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="tipoCambio"
                                label="Tipo de cambio"
                                :rules="[
                                    required('Tipo de cambio'),
                                    minLength('Tipo de cambio', 1),
                                    maxLength('Tipo de cambio', 14),
                                    decimals10('Tipo de cambio')
                                ]"
                                maxlength="14"
                                
                                ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                :disabled="true"
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="montoMonedaQuetzales"
                                label="Costos ejecutados en quetzales"
                                suffix="GTQ"
                                :rules="[
                                    required('costos ejecutados en quetzales'),
                                    minLength('costos ejecutados en quetzales', 1),
                                    maxLength('costos ejecutados en quetzales', 20),
                                    decimals10('costos ejecutados en quetzales')
                                ]"
                                maxlength="20"
                                
                                ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                color="light-blue-502"
                                class="mb-2 white--text float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="!validFormAporte"
                                :loading="btnRegistroAporteLoading"
                            >
                                Registrar aporte
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>


                <v-row>

                    <v-divider></v-divider>

                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-1"
                            :headers="headers"
                            :items="aportesActores"
                            :loading="tableAportesLoading"
                            hide-default-footer
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.id}}</td>
                                    <td>{{ item.pais}}</td>
                                    <!-- <td>{{ item.nombre}}</td> -->
                                    <td>{{ item.aporte}}</td>
                                    <td>{{ parseFloat(item.aporteD).toLocaleString("en-US", { style: 'currency', currency: 'USD',  maximumFractionDigits: 5 })  }}</td>
                                    <td>{{ parseFloat(item.tipoCambioEFIN).toFixed(5).toString() }}</td>
                                    <td>{{ parseFloat(item.aporteQ).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 5 })  }}</td>
                                    <!-- <td>
                                        <v-chip
                                            label
                                            class="ma-2"
                                            :color="
                                                item.estadosId === 1
                                                ? 'blue-grey lighten-4'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-4'
                                                : 'pink lighten-4'
                                            "
                                            :text-color="
                                                item.estadosId === 1
                                                ? 'blue-grey lighten-1'
                                                : item.estadosId === 2
                                                ? 'deep-orange lighten-1'
                                                : 'pink darken-1'
                                            "
                                            small
                                            >
                                            {{ item.estado }}
                                            </v-chip>
                                    </td>           -->                      
                                    <td>
                                        <v-btn v-if="item.estadosId==1"
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            color="blue-grey lighten-5"
                                            :disabled="btnEliminarDisabled"
                                            @click="eliminarAporte(item.id)"
                                            >
                                            <v-icon left>mdi-delete</v-icon> Eliminar
                                        </v-btn>

                                    </td>
                                </tr>
                            </template>

                            <template v-slot:no-data>
                                <p class="text-h7">
                                    <v-icon left>mdi-alert</v-icon> No existen datos registrados
                                </p>
                            </template>

                        </v-data-table>

                    </v-col>
                    <!--fin:: tabla de documentos cargados -->
                </v-row>
                
            </v-card-text>

           
        </v-card>

        <!--fin:: carga de imagenes ejecucion fisica-->






        
        <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
        transition="scroll-y-transition"
        ></DialogLoader>
        
        <!--Inicio:: Snack alert-->
        <SnackAlert ref="snackalert"></SnackAlert>
        <!-- Fin:: Snack alert-->
        
    </div>
    
</div>

</template>

<script>
import moment from "moment";
//import Vue from 'vue';
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
//import { OBTENER_PAISES } from "@/core/services/store/catalogos/paises/pais.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_SEGUIMIENTO_INICIATIVA, 
         ACTUALIZAR_SEGUIMIENTO_INICIATIVA,
         OBTENER_APORTES_ACTORES_EFIN,
         REGISTRAR_APORTE_ACTORES_EFIN,
         ELIMINAR_APORTE_ACTOR_EFIN

     } from "@/core/services/store/negociaciones/iniciativas/seguimientos/seguimiento.module";



export default {
    name: "SeccionEjecucionFinancieraSeguimiento",
    components: {
        DialogLoader,
        SnackAlert
    },
    props: ['seguimientoId', 'negociacionId'],
    data() {
        return {
                
                skeletonLoading: false,
                validForm: false,
                validFormArchivo:false,
                validFormAporte: false,
                btnRegistroAporteLoading: false,
                btnRegistroLoading: false,
                btnRegistroArchivoLoading:false,
                ddPaisesLoading: false,
                tableLoading: false,
                tableDocumentosLoading: false,
                documentos: [],
                imagenes: [],
                tableAportesLoading: false,
                btnRegistroAvanceText: "Registrar avance",
                dialogLoaderText: "",
                dialogLoaderVisible:false,
                items: [],
                fechaTipoCambio: "",
                datosEjecucion: {
                    porcentajeAvanceEFIN: 0,
                    costosEjecutadosQEFIN: 0,
                    //costosEjecutadosDEFIN: 0,
                    //fechaTipoCambio: "",
                    //tipoCambioEFIN: 0,
                },
                porcentajeAvanceEFIN: "",
                costosEjecutadosQEFIN: "",
                costosEjecutadosDEFIN: "",
                tipoCambioEFIN: "",
                aportesActores:[],
                paises: [],
                roles: [],
                tiposAporte: [],
                fuentes: [{id: 1, fuente: "Recursos nacionales"}, {id: 2, fuente: "Recursos externos"}],
                datosAporte: {
                    paisId: 0,
                   // rolesActorId: 0,
                    tipoAporteId: 0,
                    aporteD: 0,
                    aporteQ: "",
                    fechaTipoCambio:"",
                    tipoCambioEFIN: 0,
                    fuente: "",
                    estadosId: 1,
                    usuarioCreacion: "admin",
                    seguimientoId: this.seguimientoId

                },
                btnEliminarDisabled: false,

                fuenteSeleccionada: {},
                montoMonedaDolares: '',
                montoMonedaQuetzales: '',
                tipoCambio: '',
                ...validations

            }
        
    },

    methods: {

             
        async obtenerPaises() {
            this.paises = [];
            this.ddPaisesLoading = true;

            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Pais/Actores/${this.negociacionId}`})
                .then(res => {
                
                    if(res.status===200){
                        this.paises = res.data;
                    }
                //console.log(this.paises);
                //console.log(this.items)
                this.ddPaisesLoading = false;
                })
                .catch(() => {
                this.ddPaisesLoading = false;
                });
            },

        async obtenerRoles() {
            /*this.roles = [
                { id: 1, nombreRol: "Rol 1" },
                { id: 2, nombreRol: "Rol 2" },
                { id: 3, nombreRol: "Rol 3" }
            ];*/

            this.roles=[];

            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'RolesActores/all/1'})
                .then(res => {
                if (res.status === 200) {
                    this.roles = res.data;
                }
                })
                .catch(() => {
                    this.roles = [];
                });
        },

         //Obtener los roles de los actores
        async obtenerTiposAporte(){
            this.tiposAporte = [];
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TipoAporte/all/1'})
                .then(res => {
                if (res.status === 200) {
                    this.tiposAporte = res.data;
                }
                })
                .catch(() => {
                    this.tiposAporte = [];
                });
        },


        resetItems(){
            this.datosEjecucion={
                    porcentajeAvanceEFIN: 0,
                    costosEjecutadosQEFIN: 0,
                    //costosEjecutadosDEFIN: 0,
                    //fechaTipoCambio: "",
                    //tipoCambioEFIN: 0
                };

            this.porcentajeAvanceEFIN="";
            this.costosEjecutadosQEFIN="";
            this.costosEjecutadosDEFIN="";
            this.tipoCambioEFIN="";
        },



        async obtenerDatosSeguimiento(seguimientoId){
            this.resetItems();
            this.datosEjecucion = [];
            //this.skeletonLoading = true;
            this.$store
                .dispatch(OBTENER_SEGUIMIENTO_INICIATIVA, seguimientoId)
                .then(res => {
                    //console.log(res);
                    if (res.status === 200) {
                        this.datosEjecucion = res.data;
                    // this.datosEjecucion.porcentajeAvance;
                        this.fechaTipoCambio = "";
                        /* if(this.datosEjecucion.fechaTipoCambio) {
                            this.fechaTipoCambio = moment(this.datosEjecucion.fechaTipoCambio, "YYYY-MM-DD").format("DD-MM-YYYY");
                        }
 */
                        if(this.datosEjecucion.porcentajeAvanceEFIN){
                            this.porcentajeAvanceEFIN = this.datosEjecucion.porcentajeAvanceEFIN.toString();
                        }
                        if(this.datosEjecucion.costosEjecutadosQEFIN){
                            this.costosEjecutadosQEFIN = this.datosEjecucion.costosEjecutadosQEFIN.toString();
                        }
                        /* if(this.datosEjecucion.costosEjecutadosDEFIN){
                            this.costosEjecutadosDEFIN = this.datosEjecucion.costosEjecutadosDEFIN.toString();
                        } */
                        /* if(this.datosEjecucion.tipoCambioEFIN){
                            this.tipoCambioEFIN = this.datosEjecucion.tipoCambioEFIN.toString();
                        } */

                        
                        
                        
                        
                        this.btnRegistroAvanceText = "Actualizar información";
                    //   this.obtenerDocumentosCargados(this.seguimientoId);
                    //  this.obtenerImagenesCargadas(this.seguimientoId);
                    this.skeletonLoading = false;
                    this.cargarDocumento=false;

                    //console.log(this.datosEjecucion)
                    } /* else {
                        this.$refs.snackalert.SnackbarShow(
                        "warning",
                        "Alerta",
                        `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
                        );
                    } */
                    this.skeletonLoading = false;
               
                })
                .catch(error => {
                    console.log(error)
                    this.$refs.snackalert.SnackbarShow(
                        "warning",
                        "Alerta",
                        `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.1`
                    );
                    this.skeletonLoading = false;
                
                });
        },
        
        
        //Obtener los aportes por actores
        async obtenerAportes(seguimientoId){
            this.aportesActores = [];
            await this.$store
                .dispatch(OBTENER_APORTES_ACTORES_EFIN, {id: seguimientoId})
                .then(res => {
                if (res.status === 200) {
                    this.aportesActores = res.data;
                }
                })
                .catch(() => {
                    this.aportesActores = [];
                });
        },

        async registrarItem(){
            this.btnRegistroLoading=true;
            //this.datosEjecucion.fechaTipoCambio = moment(this.fechaTipoCambio, "DD-MM-YYYY").format("YYYY-MM-DD");

            //this.datosEjecucion.tipoCambioEFIN = parseFloat(this.datosEjecucion.tipoCambioEFIN);
            this.datosEjecucion.seguimientoId = this.seguimientoId;
            this.datosEjecucion.porcentajeAvanceEFIN = parseInt(this.porcentajeAvanceEFIN);
            this.datosEjecucion.costosEjecutadosQEFIN = parseFloat(this.costosEjecutadosQEFIN);
            //this.datosEjecucion.costosEjecutadosDEFIN = parseFloat(this.costosEjecutadosDEFIN);
            //this.datosEjecucion.tipoCambioEFIN = parseFloat(this.tipoCambioEFIN);
           
            await this.$store
            .dispatch(ACTUALIZAR_SEGUIMIENTO_INICIATIVA, {datosItem: this.datosEjecucion, id: this.datosEjecucion.seguimientoId, endpoint: "EjecucionFinanciera"})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.cargarDocumento=true;
                   // this.obtenerItems(this.seguimientoId);       
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);

                    this.$emit('obtener-items');
                          
                    //this.resetItems(2);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroLoading=false;
            })
            .catch(error => {
                console.log(error)
                 this.btnRegistroLoading=false;
                 this.$refs.snackalert.SnackbarShow('warning', 'Alerta', `Ha ocurrido un error durante el registro. ${error}`);
                 //this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`);
            });
        },


        configurarCargaDocumento(id){
            this.cargarDocumento=true;
            this.datosDocumento.ejecucionFisicaId = id;
            this.obtenerDocumentosCargados(id);

        },


        async registrarAporte(){
            this.btnRegistroAporteLoading=true;
            this.datosAporte.fuente = this.fuenteSeleccionada.fuente;

            this.datosAporte.aporteD = parseFloat(this.montoMonedaDolares);
            this.datosAporte.aporteQ = parseFloat(this.montoMonedaQuetzales);
            this.datosAporte.tipoCambioEFIN = parseFloat(this.tipoCambio);
            this.datosAporte.fechaTipoCambio = moment(this.fechaTipoCambio, "DD-MM-YYYY").format("YYYY-MM-DD");

                     
           
            this.datosAporte.seguimientoId = this.seguimientoId;
           
            await this.$store
            .dispatch(REGISTRAR_APORTE_ACTORES_EFIN, {datos: this.datosAporte})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    //this.cargarDocumento=true;
                    this.obtenerAportes(this.seguimientoId);       
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.resetFormAportes();
                    this.$emit('obtener-items');
                          
                    //this.resetItems(2);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                 this.btnRegistroAporteLoading=false;
            })
            .catch(error => {

                this.btnRegistroAporteLoading=false;
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', `Ha ocurrido un error durante el registro. ${error}`);
                 //this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`);
            });

        },


        resetFormAportes(){
            this.datosAporte= {
                    paisId: 0,
                   // rolesActorId: 0,
                    tipoAporteId: 0,
                    aporteD: 0,
                    aporteQ: 0,
                    fechaTipoCambio:"",
                    tipoCambioEFIN: 0,
                    fuente: "",
                    estadosId: 1,
                    usuarioCreacion: "admin",
                    seguimientoId: this.seguimientoId

                };
            this.tipoCambio="";
            this.montoMonedaDolares="";
            this.fechaTipoCambio="";
        },


        async eliminarAporte(id){
            this.btnEliminarDisabled=false;
            await this.$store
            .dispatch(ELIMINAR_APORTE_ACTOR_EFIN, id)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerAportes(this.seguimientoId);              
                   // this.resetItems();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        }

    },

    created(){
        //this.obtenerItems(this.seguimientoId);
        this.obtenerDatosSeguimiento(this.seguimientoId);
        this.obtenerPaises();
        this.obtenerRoles();
        this.obtenerTiposAporte();
        this.obtenerAportes(this.seguimientoId);
        //this.obtenerImagenesCargadas(this.seguimientoId);
        //this.obtenerDocumentosCargados(this.seguimientoId);
    },

    watch:{

        montoMonedaDolares : function(){
            this.montoMonedaQuetzales = parseFloat(this.montoMonedaDolares * this.tipoCambio).toFixed(2).toString();
        },

        tipoCambio : function() {
            this.montoMonedaQuetzales = parseFloat(this.montoMonedaDolares * this.tipoCambio).toFixed(2).toString();
        }

    },

    computed: {

        headers() {
            return [
                {
                    text: "Id",
                    align: "start",
                    sortable: true,
                    value: "id"
                },
                {
                    text: "Pais",
                    align: "start",
                    sortable: true,
                    value: "pais"
                },
               
                 {
                    text: "Tipo de aporte",
                    align: "start",
                    sortable: true,
                    value: "nombre"
                },
                {
                    text: "Aporte USDM",
                    align: "start",
                    sortable: true,
                    value: "aporteD"
                },
                {
                    text: "Tipo de cambio",
                    align: "start",
                    sortable: true,
                    value: "tipoCambioEFIN"
                },
                {
                    text: "Aporte GTQ",
                    align: "start",
                    sortable: true,
                    value: "aporteQ"
                },
                {
                    text: "Acciones",
                    align: "start",
                    sortable: false,
                    value: ""
                }
                
            ]
        },

        headersDocumentos() {
            {
            return [
                {
                    text: "Nombre del documento",
                    align: "start",
                    sortable: false,
                    value: "tipoDocumento"
                },
                {
                    text: "Estado",
                    align: "start",
                    sortable: false,
                    value: "estado"
                },
                {
                    text: "Acciones",
                    align: "start",
                    sortable: true,
                    value: "nombreMoneda"
                },
            ]
            }
        }
    }
}

</script>