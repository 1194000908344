<template lang="">
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Registro de avances de la iniciativa {{ datosNegociacion.id }}
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color="light-blue darken-2"
              dark
              class="white--text mb-2 float-right"
              @click="modalNuevo"
            >
              Registrar avance
            </v-btn>
          </v-col>
        </v-row>
        <v-card outlined>

            <v-row>
                <v-col cols="12" md="8" sm="12" xs="12" class="pb-0">

                    <v-list-item two-line>
                        <v-list-item-content class="pb-0 pt-0">
                            <v-list-item-title class="text-h7 text-uppercase">Nombre de la iniciativa</v-list-item-title>
                            <v-list-item-subtitle class="text-subtitle-1">{{datosItem.nombreIniciativa}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-col>

                <v-col cols="12" md="4" sm="4" xs="12" class="pb-0  xs-float-left">

                    <v-btn 
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize mt-4 float-right  xs-float-left"
                        small
                        depressed
                        color="blue-grey lighten-5"
                        @click="descargarArchivo(datosItem.pathDocumentoIniciativa)"
                        >
                        <v-icon left>mdi-file-download-outline</v-icon> Documento respaldo
                    </v-btn>

                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">

                    <v-list-item two-line>
                        <v-list-item-content class="pb-0 pt-0">
                            <v-list-item-title class="text-h7 text-uppercase">Objetivo de lainiciativa</v-list-item-title>
                            <v-list-item-subtitle class="text-subtitle-1">{{datosItem.objetivo}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-col>

                <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">

                    <v-list-item two-line>
                        <v-list-item-content class="pb-0 pt-0">
                            <v-list-item-title class="text-h7 text-uppercase">Fecha aprobación</v-list-item-title>
                            <v-list-item-subtitle class="text-subtitle-1">{{datosItem.fechaAprobacion | formatDate}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-col>

                <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">

                    <v-list-item two-line>
                        <v-list-item-content class="pb-0 pt-0">
                            <v-list-item-title class="text-h7 text-uppercase">Fecha inicio vigencia</v-list-item-title>
                            <v-list-item-subtitle class="text-subtitle-1">{{datosItem.inicioVigencia | formatDate}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-col>

                <v-col cols="12" md="4" sm="4" xs="12" class="pt-0">

                    <v-list-item two-line>
                        <v-list-item-content class="pb-0 pt-0">
                            <v-list-item-title class="text-h7 text-uppercase">Fecha fin vigencia</v-list-item-title>
                            <v-list-item-subtitle class="text-subtitle-1">{{datosItem.finalizaVigencia | formatDate}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-col>

            </v-row>

            <v-row class="col-md-12 m-0 p-0">
                <v-divider></v-divider>
            </v-row>

            <v-row>
                <v-col cols="12" md="4" sm="4" xs="12" class="pt-0">

                    <v-list-item two-line>
                        <v-list-item-content class="pb-0 pt-0">
                            <v-list-item-title class="text-h7 text-uppercase">Porcentaje de avance Físico</v-list-item-title>
                            <v-list-item-subtitle class="text-h5">{{porcentajeAvanceFisico}}%</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-col>

                <v-col cols="12" md="4" sm="4" xs="12" class="pt-0">

                    <v-list-item two-line>
                        <v-list-item-content class="pb-0 pt-0">
                            <v-list-item-title class="text-h7 text-uppercase">Porcentaje de avance Financiero</v-list-item-title>
                            <v-list-item-subtitle class="text-h5">{{porcentajeAvanceFinanciero}}%</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-col>
            </v-row>
           
        </v-card>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!--<div class="separator separator-solid"></div>-->

        <!--incion:: proyecto -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="elevation-0"
              :headers="headers"
              :items="seguimientos"
              :search="filtro"
              :loading="tableLoading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}'
              }"
            >
              <template v-slot:top>
                <!-- v-container, v-col and v-row are just for decoration purposes. -->
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12">
                      <v-text-field
                        autocomplete="off"
                        class="required"
                        dense
                        filled
                        color="blue-grey lighten-2"
                        v-model="filtro"
                        label="Fecha Inicio/Fin del seguimiento..."
                        maxlength="200"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12">
                      <v-select
                        v-model="estados"
                        :items="estados"
                        :loading="false"
                        dense
                        filled
                        label="Estado"
                        :no-data-text="'No existen estados registrados'"
                        menu-props="auto"
                        return-object
                      ></v-select>
                    </v-col>

                    <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                      <v-btn
                        class="ma-0 white--text d-none"
                        medium
                        color="light-blue darken-2"
                      >
                        <v-icon left>mdi-magnify</v-icon> Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <template v-slot:item="{ item }">
                <tr>
                  <td> {{ item.id }} </td>
                  <td> {{ item.fechaInicio | formatDate }}</td>
                  <td> {{ item.fechaFinaliza | formatDate }}</td>
                  <td> {{ item.porcentajeAvanceEFIS }}%</td>
                  <td> {{ item.porcentajeAvanceEFIN }}%</td>
                  <td>
                    <v-chip
                      class="ma-2 font-weight-medium"
                      label
                      :color="
                        item.estadosId === 1
                          ? 'blue-grey lighten-4'
                          : item.estadosId === 2
                          ? 'blue lighten-4'
                          : item.estadosId === 6
                          ? 'cyan lighten-4'
                          : item.estadosId === 3
                          ? 'teal lighten-4'
                          : item.estadosId === 4
                          ? 'deep-orange lighten-4'
                          : 'pink lighten-4'
                      "
                      :text-color="
                        item.estadosId === 1
                          ? 'blue-grey lighten-1'
                          : item.estadosId === 2
                          ? 'blue lighten-1'
                          : item.estadosId === 6
                          ? 'cyan darken-1'
                          : item.estadosId === 3
                          ? 'teal lighten-1'
                          : item.estadosId === 4
                          ? 'deep-orange lighten-1'
                          : 'pink darken-1'
                      "
                      small
                    >
                      {{ item.estado }}
                    </v-chip>
                  </td>
                  <td>
                    <v-btn
                      class="
                                        ma-2
                                        btn-bg-light
                                        blue-grey--text
                                        lighten-2--text
                                        font-weight-medium
                                        text-capitalize
                                    "
                      small
                      depressed
                      color="blue-grey lighten-5"
                      @click="mostrarModalSeccionesSeguimiento(item.id)"
                    >
                      <v-icon left>mdi-plus</v-icon> Datos de avance
                    </v-btn>

                    <!--<v-btn
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      @click="mostrarModalActualizar(item.id)"
                    >
                      <v-icon left>mdi-pencil</v-icon> Actualizar
                    </v-btn>-->

                    <v-btn
                      v-if="item.estadosId === 1"
                      class="
                                        ma-2
                                        btn-bg-light
                                        blue-grey--text
                                        lighten-2--text
                                        font-weight-medium
                                        text-capitalize
                                    "
                      small
                      depressed
                      color="blue-grey lighten-5"
                      @click="prepararEnvio(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>

                   
                    
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!--fin:: proyectos -->
      </v-card-text>
    </v-card>


    <v-dialog
      v-model="dialogRegistroSeguimiento"
      max-width="600px"
      transition="dialog-bottom-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalTitle }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistroSeguimiento = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text>
            <v-form ref="form" v-on:submit.prevent="registrarSeguimiento" v-model="validFormSeguimiento">
                <v-row>
                    <!--inicio:: campo-->
                    <v-col cols="12" md="6" sm="12" class="pb-0">
                        <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        v-model="fechaInicio"
                        label="Fecha de inicio del seguimiento"
                        :rules="[
                            required('Fecha de inicio del seguimiento'),
                            minLength('Fecha de inicio del seguimiento', 8),
                            dateFormat('Fecha de inicio del seguimiento')
                        ]"
                        maxlength="10"
                        v-mask="'##/##/####'"
                        ></v-text-field>
                    </v-col>
                    <!--fin:: campo-->

                    <!--inicio:: campo-->
                    <v-col cols="12" md="6" sm="12" class="pb-0">
                        <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        :disabled="!fechaInicio"
                        color="blue-grey lighten-2"
                        v-model="fechaFinaliza"
                        label="Fecha de finalización del seguimiento"
                        :rules="[
                            required('Fecha de finalización del seguimiento'),
                            minLength('Fecha de finalización del seguimiento', 8),
                            dateFormat('Fecha de finalización del seguimiento'),
                            dateGreatherThan('Fecha de finalización del seguimiento', fechaInicio)
                        ]"
                        maxlength="10"
                        v-mask="'##/##/####'"
                        ></v-text-field>
                    </v-col>
                    <!--fin:: campo-->

                </v-row>


                <v-row>

                    <v-col cols="12" md="12" sm="12" class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn
                        class="ma-0 white--text float-right mt-3 float-right"
                        medium
                        type="submit"
                        color="light-blue darken-2"
                        :disabled="!validFormSeguimiento"
                        :loading="btnRegistroSeguimientoLoading"
                        >
                        <v-icon left>mdi-plus</v-icon> Registrar seguimiento
                        </v-btn>
                    </v-col>

                </v-row>

            </v-form>

        </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRegistro"
      max-width="1024px"
      transition="dialog-bottom-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalTitle }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text  class="pl-0 pr-0 pt-0">
          <v-tabs
            v-model="tab"
            background-color="light-blue darken-2"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Ejecución Física
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
              Actividades
              <v-icon>mdi-plus</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
              Ejecución Financiera
              <v-icon>mdi-cash-100</v-icon>
            </v-tab>
            <v-tab href="#tab-4">
              Documentos adicionales
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-5" v-if="1>2">
              Datos de contactos
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text>
                  <SeccionEjecucionFisicaSeguimiento ref="seccionEjecucionFisica" :seguimientoId="seguimientoId" @obtener-items="obtenerSeguimientosIniciativa"/>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <SeccionActividadesSeguimiento ref="seccionActividades" :seguimientoId="seguimientoId" :iniciativaId="iniciativaId" :negociacionId="negociacionId"/>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text> 
                  <SeccionEjecucionFinancieraSeguimiento ref="seccionEjecucionFinanciera" :seguimientoId="seguimientoId" :negociacionId="negociacionId" @obtener-items="obtenerSeguimientosIniciativa"/>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text> 
                  <SeccionDocumentosSeguimiento ref="seccionDocumentos" :seguimientoId="seguimientoId"/>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text> 
                <SeccionContactosDirecta ref="seccionContactos" :iniciativaId="iniciativaId"/></v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>
<script>
import Vue from 'vue';
import moment from "moment";
import DownloadFile from "@/core/untils/downloadfile.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";

import SeccionEjecucionFisicaSeguimiento from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/seguimientos/SeccionEjecucionFisicaSeguimiento.vue";
import SeccionEjecucionFinancieraSeguimiento from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/seguimientos/SeccionEjecucionFinancieraSeguimiento.vue";

import SeccionDocumentosSeguimiento from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/seguimientos/SeccionDocumentosSeguimiento.vue";

import SeccionActividadesSeguimiento from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/seguimientos/SeccionActividadesSeguimiento.vue";

//import SeccionVinculacionDirecta from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/SeccionVinculacionDirecta.vue";
import SeccionContactosDirecta from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/SeccionContactosDirecta.vue";

import { OBTENER_SEGUIMIENTOS_INICIATIVA, REGISTRAR_SEGUIMIENTO_INICIATIVA } from "@/core/services/store/negociaciones/iniciativas/seguimientos/seguimiento.module";


import {  OBTENER_INICIATIVA } from "@/core/services/store/negociaciones/iniciativadirecta.module";

moment.locale('es');
Vue.filter("formatDate", function(value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    }
});

import {
  // OBTENER_NEGOCIACIONES_DIRECTAS,
  // REGISTRAR_NEGOCIACION_DIRECTA,
  OBTENER_NEGOCIACION_DIRECTA
  // ACTUALIZAR_NEGOCIACION_DIRECTA,
  // ENVIAR_NEGOCIACION
} from "@/core/services/store/negociaciones/negociaciondirecta.module";
export default {
  name: "AdminSeguimientosIniciativa",
  components: {
    DialogLoader,
    SnackAlert,
    SeccionEjecucionFisicaSeguimiento,
    SeccionEjecucionFinancieraSeguimiento,
    SeccionDocumentosSeguimiento,
    SeccionActividadesSeguimiento,
    //SeccionVinculacionDirecta,
    SeccionContactosDirecta
  },
  data() {
    return {
      tableLoading:false,
      dialogLoaderVisible: false,
      dialogLoaderText: 'Obteniendo información...',
      porcentajeAvanceFisico:0,
      porcentajeAvanceFinanciero:0,
      items: [],
      filtro: "",
      dialogRegistro: false,
      dialogRegistroSeguimiento: false,
      modalTitle: "Información de avance",
      tab: null,
      seguimientos: [],
      seguimientoId: 0,
      datosItem:[],
      estados: [
        { text: "Todos", value: null },
        { value: 1, text: "Activo" },
        { value: 2, text: "Inactivo" }
      ],
       fechaInicio: "",
       fechaFinaliza: "",
      datosSeguimiento: {
        fechaInicio: "",
        fechaFinaliza: "",
        estadosId: 1,
        iniciativaId: this.iniciativaId,
        usuarioCreacion: "admin"
      },
      validFormSeguimiento: false,
      btnRegistroSeguimientoLoading: false,
      negociacionId: 0,
      iniciativaId: 0,
      datosNegociacion: [],
      iniciativas: [],
      ...validations
    };
  },
  methods: {

    resetForm(){
        this.datosSeguimiento = {
        fechaInicio: "",
        fechaFinaliza: "",
        estadosId: 1,
        iniciativaId: this.iniciativaId,
        usuarioCreacion: "admin"
      };
    },

    //Obtener los datos de la iniciativa
    async obtenerDatosIniciativa(){
      this.datosItem = [];
     // this.dialogLoaderVisible = true;
     
      this.$store
        .dispatch(OBTENER_INICIATIVA,  this.iniciativaId)
        .then(res => {
          if(res.status===200){
            this.datosItem = res.data;
           
          }
          //  this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.dialogLoaderVisible = false;
        });

    },

    //Obtener seguimientos por iniciativa
    async obtenerSeguimientosIniciativa(){
         this.seguimientos = [];
     // this.dialogLoaderVisible = true;
        this.tableLoading = true;
        await this.$store
            .dispatch(OBTENER_SEGUIMIENTOS_INICIATIVA,  {id: this.iniciativaId})
            .then(res => {
            if(res.status===200){
                this.seguimientos = res.data;
                this.porcentajeAvanceFisico = this.seguimientos.reduce((a,b) => a + (b['porcentajeAvanceEFIS'] || 0), 0);
                this.porcentajeAvanceFinanciero = this.seguimientos.reduce((a,b) => a + (b['porcentajeAvanceEFIN'] || 0), 0);
            }
            this.tableLoading = false;
            //  this.dialogLoaderVisible = false;
            })
            .catch(() => {
                //console.log(error)
            this.tableLoading = false;
            });
    },

    //!Descargar el archivo de respaldo

    descargarArchivo(path){
    //  console.log(path)
      DownloadFile.download(path);
    },

    modalNuevo() {
      this.accion = 1;
      this.modalTitle = "Registrar avance";
      this.btnRegistroText = "Registrar avance";
      this.switchItemEstado = true;
      this.dialogRegistroSeguimiento = true;
    },


    


    //Registrar datos de seguimiento
    async registrarSeguimiento(){
        this.btnRegistroSeguimientoLoading=true;

        this.datosSeguimiento.fechaInicio = moment(this.fechaInicio, "DD-MM-YYYY").format("YYYY-MM-DD");
        this.datosSeguimiento.fechaFinaliza = moment(this.fechaFinaliza, "DD-MM-YYYY").format("YYYY-MM-DD");
        this.datosSeguimiento.iniciativaId = this.iniciativaId;

         await this.$store
            .dispatch(REGISTRAR_SEGUIMIENTO_INICIATIVA, {datos: this.datosSeguimiento})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    //this.obtenerCostosGlobales(this.iniciativaId);             
                    //this.resetFormCostosActores();
                    this.resetForm();
                    this.dialogRegistroSeguimiento = false;
                    this.obtenerSeguimientosIniciativa();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroSeguimientoLoading=false;
            })
            .catch(error => {
                
                 this.btnRegistroSeguimientoLoading=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
    },

    //Obtener los datos de la negociación
    obtenerDatosNegociacion() {
      this.datosNegociacion = [];
      this.$store
        .dispatch(OBTENER_NEGOCIACION_DIRECTA, this.negociacionId)
        .then(res => {
          //console.log(res);
          if (res.status === 200) {
            this.datosNegociacion = this.$store.state.negociacionDirecta.negociacionDirecta;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
          );
          this.dialogLoaderVisible = false;
        });
    },

    
    //Obtener una iniciativa específica
    async mostrarModalSeccionesSeguimiento(id){
      this.accion = 2;
      this.tab = "tab-1";
      this.seguimientoId = id;

      
      if(this.$refs.seccionEjecucionFisica){
        this.$refs.seccionEjecucionFisica.resetItems();

        this.$refs.seccionEjecucionFisica.obtenerDatosSeguimiento(this.seguimientoId);
      }

      if(this.$refs.seccionEjecucionFinanciera){
        this.$refs.seccionEjecucionFinanciera.resetItems();
        this.$refs.seccionEjecucionFinanciera.obtenerPaises();
        this.$refs.seccionEjecucionFinanciera.obtenerRoles();
        this.$refs.seccionEjecucionFinanciera.obtenerTiposAporte();
        this.$refs.seccionEjecucionFinanciera.obtenerAportes(this.seguimientoId);

        this.$refs.seccionEjecucionFinanciera.obtenerDatosSeguimiento(this.seguimientoId);

      }

      if(this.$refs.seccionDocumentos){
        this.$refs.seccionDocumentos.resetItems();
        this.$refs.seccionDocumentos.obtenerDocumentosSeguimiento(this.seguimientoId);
      }

      if(this.$refs.seccionActividades){
        this.$refs.seccionActividades.resetItems();
        this.$refs.seccionActividades.obtenerActividades(this.iniciativaId);  
        this.$refs.seccionActividades.obtenerSectores();
        this.$refs.seccionActividades.obtenerPaises();
        this.$refs.seccionActividades.obtenerDepartamentos();
        /*this.$refs.seccionActividades.obtenerInstituciones().then(()=> {
          this.$refs.seccionActividades.obtenerTiposFuenteCooperante();
        
          this.$refs.seccionActividades.obtenerFuentesBilaterales().then(() => {
            
          });
        })*/
      }


      //Verificar que el componente esté cargado, si lo está, ejecutar los métodos
      if(this.$refs.seccionPresupuesto){
        this.$refs.seccionPresupuesto.obtenerRoles().then(()=>{
          this.$refs.seccionPresupuesto.obtenerPaises().then(()=>{
             this.$refs.seccionPresupuesto.obtenerPeriodos();
            this.$refs.seccionPresupuesto.obtenerTiposAporte().then(()=> {
              this.$refs.seccionPresupuesto.obtenerCostosGlobales(this.seguimientoId);
             // this.$refs.seccionPresupuesto.obtenerCostosAnuales(this.seguimientoId);
            });
          });
        });
      }

      if(this.$refs.seccionContactos){
        this.$refs.seccionContactos.resetItems();
        this.$refs.seccionContactos.obtenerTiposContacto().then(()=>{
         
          this.$refs.seccionContactos.obtenerCargos().then(()=>{

            this.$refs.seccionContactos.obtenerContactos(this.seguimientoId);
          });
        });
      }

      this.dialogRegistro=true;
    }
  },
  created() {
    this.parameters = this.$route.params;
    let iniId = this.$CryptoJS.AES.decrypt(
      this.parameters.iniciativaId,
      "KEYADMINCNS2022"
    ).toString(this.CryptoJS.enc.Utf8);

    let negoId = this.$CryptoJS.AES.decrypt(
      this.parameters.negociacionId,
      "KEYADMINCNS2022"
    ).toString(this.CryptoJS.enc.Utf8);


    this.iniciativaId = parseInt(iniId);
    this.negociacionId = parseInt(negoId);
    //console.log(this.negociacionId)
    //this.obtenerDatosNegociacion();
    this.obtenerDatosIniciativa();
    this.obtenerSeguimientosIniciativa();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Negociaciones" },
      { title: "Directa" },
      { title: "Seguimiento" }
    ]);
  },

  computed: {
    headers() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Fecha de inicio",
          align: "start",
          sortable: true,
          value: "fechaInicio"
        },
        {
          text: "Fecha de finalización",
          align: "start",
          sortable: true,
          value: "fechaFinaliza"
        },
         {
          text: "Avance Físico",
          align: "start",
          sortable: true,
          value: "porcentajeAvanceEFIS"
        },
         {
          text: "Avance Financiero",
          align: "start",
          sortable: true,
          value: "porcentajeAvanceEFIN"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  }
};
</script>
<style lang=""></style>
